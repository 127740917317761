import "./../styles/courseCard.css";

import PSM1 from "./../imgs/CourseIcons/SMCaratula.png";
import PSM2 from "./../imgs/CourseIcons/PSM2Caratula.webp";
import KSD from "./../imgs/CourseIcons/KSDCaratula.png";
import KSI from "./../imgs/CourseIcons/KSICaratula.png";
import JIRAF from "./../imgs/CourseIcons/JiraCaratula.png";
import PMI from "./../imgs/CourseIcons/PMICaratula.png";
import GDF from "./../imgs/CourseIcons/GDFCaratula.png"
import { useNavigate } from "react-router-dom";

export const CourseCard = ({name, startDate, endDate, learningCourseId, courseIdentifier}) => {

    const navigate = useNavigate();

    const handleCourseNav = () => {
        if  (courseIdentifier=== "PSMI") {
            navigate(`/courses/scrum-master/${learningCourseId}`);
        } 
        if  (courseIdentifier=== "PSM2") {
            navigate(`/courses/scrum-master-psm2/${learningCourseId}`);
        } 
        if (courseIdentifier==="KSD"){
            navigate(`/courses/kanban-system-design/${learningCourseId}`);
        } 
        if (courseIdentifier==="KSI"){
            navigate(`/courses/kanban-system-improvement/${learningCourseId}`);
        }
        if (courseIdentifier==="JIRAF"){
            navigate(`/courses/jira-fundamentals/${learningCourseId}`);
        } 
        if (courseIdentifier==="PMI"){
            navigate(`/courses/introduccion-proyectos/${learningCourseId}`);
        }
        if (courseIdentifier==="GDF"){
            navigate(`/courses/documentacion-grafica/${learningCourseId}`);
        }else {

        }
    }

    return (
        <div className="course-card-container">
            <div className="course-card-img-block">
                <img src= {courseIdentifier === "PSMI" ? PSM1 : courseIdentifier === "KSD"? KSD: courseIdentifier === "JIRAF" ? JIRAF : courseIdentifier === "PMI" ? PMI : courseIdentifier === "KSI" ? KSI : courseIdentifier === "GDF" ? GDF : courseIdentifier === "PSM2" ? PSM2 : ""} className="course-card-img" alt="course presentation"/>
            </div>
            <label className="course-card-label">Online</label>
            <h2 className="course-card-title"> {name} </h2>
            <h3 className="course-card-date"> {startDate} - {endDate}</h3>
            <button className="course-card-btn" onClick={handleCourseNav} >Más Info</button>
        </div>
    );
};
