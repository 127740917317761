import React from 'react';
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { ToogleMenu } from "../components/ToogleMenu";
import { WhatsAppButton} from "./../components/WhatsAppButtom";
import { ChatBot } from "../components/ChatBot";
import { AboutUsContent } from '../components/AboutUsContent';

import useWebMetrics from "./../hooks/useWebMetrics";

function AboutUs() {

  const {validUserId, setvalidUserId} = useWebMetrics();

  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages/>
      <AboutUsContent/>
      <Footer/>
      <WhatsAppButton/>
      <ScrollTop/>
      {/* <ToogleMenu/>
      <ChatBot/> */}
    </React.Fragment>
  )
}

export {AboutUs};
