import React from "react";
import { useParams } from "react-router-dom";

import "./../../styles/scrumMaster.css"
import { CoursePresentation } from "../../components/CoursePresentation";
import {Navbar} from "../../components/Navbar";
import { WhatsAppButton } from "../../components/WhatsAppButtom";
import { Testimonials } from "../../components/Testimonials";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { ToogleMenu } from "../../components/ToogleMenu";
import { ChatBot } from "../../components/ChatBot";

import historyIcon from "./../../imgs/CourseIcons/1.png";
import rolesIcon from "./../../imgs/CourseIcons/2.png";
import principlesIcon from "./../../imgs/CourseIcons/3.png";
import valuesIcon from "./../../imgs/CourseIcons/4.png";
import eventsIcon from "./../../imgs/CourseIcons/5.png";
import artefactsIcon from "./../../imgs/CourseIcons/6.png";

import { InfiniteCarouselView } from "../../components/InfiniteCarousel/InfiniteCarouselView";
import topicsImg from "./../../imgs/temarios1.jpg";

function DocumentacionGrafica () {
    
    let learningCourseId = useParams();
    
    return (
        <React.Fragment>
            <Navbar/>
            <div className="scrum-master-main-container">
                <CoursePresentation
                courseName="FUNDAMENTOS DE DOCUMENTACIÓN GRÁFICA"
                courseDescription="Bienvenido al Taller Práctico de Fundamentos de Documentación Gráfica. En este taller, no solo aprenderás las técnicas clave de la documentación gráfica, sino que también recibirás valiosos consejos para dar tus primeros pasos como documentador visual. Desde la introducción a la simbología hasta el uso creativo de viñetas y rostros de caricatura, este taller te equipará con las habilidades esenciales para destacar en tus reuniones, presentaciones y notas."
                learningCourseId= {learningCourseId}
                videoUrl= "6pUrK_ZdwIg"
                />
                <div className="course-goals-block">
                    <h1 className="course-goals-title">¿Qué aprenderás?</h1>
                    <div className="course-goals-content">

                        <div className="course-goals-line1">

                            <div className="course-goal-item">
                                <img src={historyIcon} className="course-item-icon" alt="hostory icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Tips</h2>
                                    <p className="goal-item-description"> Explora estrategias prácticas para dar tus primeros pasos como profesional de la documentación gráfica.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={rolesIcon} className="course-item-icon" alt="roles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Simbología</h2>
                                    <p className="goal-item-description"> Aprende a utilizar símbolos poderosos para transmitir conceptos de manera clara y concisa.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={principlesIcon} className="course-item-icon" alt="principles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Viñetas</h2>
                                    <p className="goal-item-description"> Descubre cómo las viñetas pueden mejorar la narrativa visual de tus documentos y presentaciones.</p>
                                </div>
                            </div>

                        </div>

                        <div className="course-goals-line2">
                           
                            <div className="course-goal-item">
                                <img src={valuesIcon} className="course-item-icon" alt="values icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Rostros</h2>
                                    <p className="goal-item-description"> Explora cómo agregar expresividad y personalidad a tus representaciones visuales.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={eventsIcon} className="course-item-icon" alt="events icon" />
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Aplicación</h2>
                                    <p className="goal-item-description"> Integra las técnicas aprendidas en tu proceso ágil, desde la planificación del sprint hasta la retrospectiva.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={artefactsIcon} className="course-item-icon" alt="artefacts icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Experiencias</h2>
                                    <p className="goal-item-description"> Recibe valiosos consejos de documentadores visuales con experiencia en equipos ágiles.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="course-topics-block">
                    <h1 className="course-topics-title">Temario</h1>
                    <div className="course-topics-container">
                        <div className="course-topics-description">
                            <p className="course-topics-text">Somos conscientes de los rápidos cambios en el entorno empresarial y tecnológico, por ello estamos preparados para afrontar estos retos formando a los futuros <label className="company-highlights">Scrum Masters con las competencias, técnicas y herramientas</label> que les permitan Desarrollar habilidades prácticas para destacar como documentador visual, mejorar sus notas y presentaciones con técnicas visuales impactantes para conectar con otros profesionales y colaborar de manera efectiva.</p>
                        </div>
                        <div className="course-topics-content">
                            <img src= {topicsImg} className="course-topics-img" alt="topics img"/>
                            <div className="course-topics-bullets">
                                <h2 className="course-topics-subtitle">Contenido del Curso</h2>
                                <div className="course-topics-bullets-container">
                                    <p className="course-topics-text">Este curso está diseñado para personas interesadas en iniciarse en la documentación gráfica y aquellos que desean mejorar sus habilidades visuales. Participa en ejercicios prácticos, discusiones interactivas y proyectos que te permitirán aplicar directamente lo que aprendes. El curso abordará el eje temático detallado a continuación:</p>
                                    <ul className="course-topics-list-container">
                                        <li className="course-topic-item">Introducción a la documentación gráfica</li>
                                        <li className="course-topic-item">Tips para iniciar tu camino como documentador visual</li>
                                        <li className="course-topic-item">Introducción a simbología</li>
                                        <li className="course-topic-item">Introducción a uso de viñetas </li>
                                        <li className="course-topic-item">Introducción a uso de rostros de caricatura</li>
                                        <li className="course-topic-item">Taller práctico</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="course-included-block">
                <h1 className="course-included-title">¿Qué obtendrás al inscribirte?</h1>
                <p className="course-included-text">En <label className="company-name-label"> agilexcellence</label> constantemente reinventamos la experiencia de nuestros cursos, grantizando que aprendas de las experiencias en diversos sectores de la industria de nuestros asistentes y entrenadores. 
                Aparte de nuestra garantía de no prepararte solo para aprobar un examen, al inscribirte en este curso obtendrás:</p>
                <ul className="course-included-list-container">
                    <li className="course-included-item">4 horas de formación a través de Zoom</li>
                    <li className="course-included-item">Material complementario de estudio</li>
                    <li className="course-included-item">Emisión de certificado de asistencia al curso</li>
                    <li className="course-included-item">Grabación de sesiones en vivo y disponibilización en nuestra plataforma</li>
                </ul>
            </div>
            
            <div className="course-community-block">
                <h1 className="course-community-title">Únete a la comunidad</h1>
                <p className="course-community-text">Inicia tu camino como Scrum Master dentro de la comunidad de<label className="company-name-label"> agilexcellence</label>, aprende de las experiencias en diversos sectores de la industria donde Scrum es utilizado.</p>
            </div>
            <InfiniteCarouselView/>
            
            {/* <Testimonials/> */}
            <Footer/>
            <WhatsAppButton/>
            <ScrollTop/>
            {/* <ToogleMenu/> */}
            {/* <ChatBot/> */}
        </React.Fragment>
    );
}

export {DocumentacionGrafica};