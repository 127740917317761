import React from "react";
import "./../styles/loading.css";

function Loading () {

    return(
        <div class="lading-container">
            <div class="ball1 ball"></div>
            <div class="ball2 ball"></div>
            <div class="ball3 ball"></div>
            <div class="ball4 ball"></div>
        </div>
    );

}

export { Loading};