import React from "react";
import { useParams } from "react-router-dom";

import "./../../styles/scrumMaster.css"
import { CoursePresentation } from "../../components/CoursePresentation";
import {Navbar} from "../../components/Navbar";
import { WhatsAppButton } from "../../components/WhatsAppButtom";
import { Testimonials } from "../../components/Testimonials";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { ToogleMenu } from "../../components/ToogleMenu";
import { ChatBot } from "../../components/ChatBot";

import historyIcon from "./../../imgs/CourseIcons/1.png";
import rolesIcon from "./../../imgs/CourseIcons/2.png";
import principlesIcon from "./../../imgs/CourseIcons/3.png";
import valuesIcon from "./../../imgs/CourseIcons/4.png";
import eventsIcon from "./../../imgs/CourseIcons/5.png";
import artefactsIcon from "./../../imgs/CourseIcons/6.png";

import { InfiniteCarouselView } from "../../components/InfiniteCarousel/InfiniteCarouselView";


import topicsImg from "./../../imgs/temarios1.jpg";

function KanbanSystemDesing () {
    
    let learningCourseId = useParams();
    
    return (
        <React.Fragment>
            <Navbar/>
            <div className="scrum-master-main-container">
                <CoursePresentation
                    courseName="CURSO DE KANBAN SYSTEM DESING"
                    courseDescription="A lo largo de este entrenamiento haremos un recorrido por los modelos de visualización del
                    trabajo y la importancia de implementar modelos que permitan esta visualización, con el fin de
                    tener procesos de toma de decisión más efectivos, tener equipos autónomos y que mejoren los
                    resultados, asegurando valor en cada uno de los entregables."
                    learningCourseId= {learningCourseId}
                    videoUrl= "bv_DlrXRjtQ"
                />
                <div className="course-goals-block">
                    <h1 className="course-goals-title">¿Qué aprenderás?</h1>
                    <div className="course-goals-content">

                        <div className="course-goals-line1">

                            <div className="course-goal-item">
                                <img src={historyIcon} className="course-item-icon" alt="hostory icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Historia</h2>
                                    <p className="goal-item-description"> Conoce quienes han contribuido a la creación del método Kanban y su origen.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={rolesIcon} className="course-item-icon" alt="roles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Métricas</h2>
                                    <p className="goal-item-description"> Conoce las diferentes métricas utilizadas en el método Kanban para impulsar la mejora continua de tu flujo de trabajo.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={principlesIcon} className="course-item-icon" alt="principles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Prácticas</h2>
                                    <p className="goal-item-description"> Conoce las prácticas bajo los cuales trabaja el método Kanban.</p>
                                </div>
                            </div>

                        </div>

                        <div className="course-goals-line2">
                           
                            <div className="course-goal-item">
                                <img src={valuesIcon} className="course-item-icon" alt="values icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Principios</h2>
                                    <p className="goal-item-description"> Conoce los principios del método Kanban y cómo vivirlos en el trabajo del día a día, así como su evolución en madurez.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={eventsIcon} className="course-item-icon" alt="events icon" />
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Cadencias</h2>
                                    <p className="goal-item-description"> Aprenderemos cuáles son las cadencias del método Kanban para un nivel de maduréz inicial.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={artefactsIcon} className="course-item-icon" alt="artefacts icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">STATIK</h2>
                                    <p className="goal-item-description"> Aprenderemos cómo identificar el flujo de trabajo de un equipo, definir sus políticas, clases de servicio, tipos de trabajo y más.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="course-topics-block">
                    <h1 className="course-topics-title">Temario</h1>
                    <div className="course-topics-container">
                        <div className="course-topics-description">
                            <p className="course-topics-text">Somos conscientes de los rápidos cambios en el entorno empresarial y tecnológico, así como de la necesidad de potenciar la entrega de los equipos, por ello estamos preparados para afrontar estos retos formando a los futuros <label className="company-highlights">Scrum Masters con las competencias, técnicas y herramientas</label> que les permitan guiar equipos de alto rendimiento a la entrega continua de valor, a través de un nivel de entendimiento a detalle del método Kanban.</p>
                        </div>
                        <div className="course-topics-content">
                            <img src= {topicsImg} className="course-topics-img" alt="topics img"/>
                            <div className="course-topics-bullets">
                                <h2 className="course-topics-subtitle">Contenido del Curso</h2>
                                <div className="course-topics-bullets-container">
                                    <p className="course-topics-text">El curso de certificación KSD, es el curso introductorio a la formación de practicantes y nuevos candidatos a certificar sus conocimientos en el método Kanban de acuerdo a los lineamientos de <label className="company-highlights">Kanban University</label>. El curso abordará el eje temático detallado a continuación:</p>
                                    <ul className="course-topics-list-container">
                                        <li className="course-topic-item">Qué significa Kanban</li>
                                        <li className="course-topic-item">Recorrido por el método Kanban</li>
                                        <li className="course-topic-item">Prácticas y modelos de implementación</li>
                                        <li className="course-topic-item">Practicas simuladas de un proceso y la mejora continua con un sistema Kanban</li>
                                        <li className="course-topic-item">Métricas, inspección y mejoras</li>
                                        <li className="course-topic-item">Implementación de Kanban con STATIK</li>
                                        <li className="course-topic-item">Casos prácticos de implementación con Statik en casos reales de asistentes</li>
                                        <li className="course-topic-item">Patrones para el diseño de tickets y tableros</li>
                                        <li className="course-topic-item">Primeros pasos para la implementación de sistemas Kanban</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div className="course-community-block">
                <h1 className="course-community-title">Únete a la comunidad</h1>
                <p className="course-community-text">Inicia tu camino como Scrum Master dentro de la comunidad de<label className="company-name-label"> agilexcellence</label>, aprende de las experiencias en diversos sectores de la industria donde Scrum es utilizado.</p>
            </div>
            <InfiniteCarouselView/>
            
            {/* <Testimonials/> */}
            <Footer/>
            <WhatsAppButton/>
            <ScrollTop/>
            {/* <ToogleMenu/> */}
            {/* <ChatBot/> */}
        </React.Fragment>
    );
}

export {KanbanSystemDesing};