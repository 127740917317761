import React,{ useState, useEffect } from "react";
import { getGraphQl } from "./ApiCalls";
import Moment from "moment";

export default function useWebMetrics(){

    let [UserIdToken, setUserIdToken] = useState();

    function getUserIdInfo () {

        const userInfoString = localStorage.getItem("agilexcellence.io:UserIdToken");
        let userInfo = "";

        if (userInfoString !== "undefined") {
            userInfo = JSON.parse(userInfoString);
        }
        
        return userInfo;
    };

    useEffect(() => {

        const userIdInfoObj= getUserIdInfo();

        if (userIdInfoObj){

            let lastVisitDate = Moment(userIdInfoObj.lastVisitDate);
            let nowDate = Moment();
            let duration = Moment.duration(nowDate.diff(lastVisitDate,'hours'));

            if (duration>1) {
                
                let graphQuery = `
                    mutation {
                        updateWebsiteVisits(WebsiteVisitor:{validUserId:false, userId:"${userIdInfoObj.userId}"}) {
                        userId
                        lastVisitDate
                        validUserId
                        visitorNumber
                        }
                    }
                `;
                
                (async () => {
                    const {data:{data:{updateWebsiteVisits}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
                    delete updateWebsiteVisits.visitorNumber;
                    delete updateWebsiteVisits.validUserId;
                    setUserIdToken(updateWebsiteVisits);
                    localStorage.setItem('agilexcellence.io:UserIdToken', JSON.stringify(updateWebsiteVisits));
                })();
            }

        }

        if (userIdInfoObj == null || userIdInfoObj.userId == null ){
            
            let graphQuery = `
                mutation {
                    updateWebsiteVisits(WebsiteVisitor:{validUserId:false}) {
                    userId
                    lastVisitDate
                    validUserId
                    visitorNumber
                    }
                }
            `;

            (async () => {
                const {data:{data:{updateWebsiteVisits}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
                delete updateWebsiteVisits.visitorNumber;
                delete updateWebsiteVisits.validUserId;
                setUserIdToken(updateWebsiteVisits);
                localStorage.setItem('agilexcellence.io:UserIdToken', JSON.stringify(updateWebsiteVisits));
            })();
    
        }
        setUserIdToken(getUserIdInfo());
  
    }, []);


    return {
        response:"hola"
        //setUserIdToken: saveUserIdToken, UserIdToken
    }

}