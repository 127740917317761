import React from "react";
import "./../styles/aboutUs.css"

import { useNavigate } from "react-router-dom";

import img2 from "./../imgs/AboutUs/2.webp";
import img3 from "./../imgs/AboutUs/3.webp";
import butterflyImg from "./../imgs/AboutUs/MariposaOrigami.png";
import duckImg from "./../imgs/AboutUs/GanzoOrigami.png";
import birdImg from "./../imgs/AboutUs/BirdOrigami.png";
import dinoImg from "./../imgs/AboutUs/DinoOrigami.png";

import JesusImg from "./../imgs/OurTeam/Jesus.jpg";
import CamilaImg from "./../imgs/OurTeam/Camila.JPG";


function AboutUsContent () {

    const navigate = useNavigate();

    return(
        <section className="abut-us-banner-container">

            <div className="about-us-block1">
                <div className="block1-text">
                    <p className="block1-paragrah">SOMOS <label className="company-name-label">AGILEXCELLENCE</label>, UNA COMPAÑÍA ENFOCADA EN TRANSFORMAR LA FORMA CÓMO LAS PERSONAS TRABAJAN.</p>
                </div>
                <div className="block1-img">
                    <img src= {birdImg}/>
                </div>
            </div>

            <div className="about-us-block2">
                <div className="about-us-block2-container">
                    <div className="block2-text">
                        <h2 className="block-title">¿CÓMO LO HACEMOS?</h2>
                    </div>
                    <div className="about-us-block2-right">
                        <div className="about-us-block2-right-item">
                            <img src={butterflyImg} className="about-us-block2-item-img"/>
                            <p className="block-paragrah"> Desarrollamos capacidades <label className="company-highlights">empoderando a las personas</label> para afrontar los desafíos de las dinámicas organizaciones.</p>
                        </div>
                        <div className="about-us-block2-right-item">
                            <img src={duckImg}  className="about-us-block2-item-img"/>
                            <p className="block-paragrah"> Acompañamos organizaciones en la búsqueda de los <label className="company-highlights">mejores talentos </label>para conformar sus equipos de trabajo.</p>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="about-us-block2-quote"> ¡Somos diferentes! Y por eso pensamos diferente y lo hacemos diferente.</p>
                    <p className="about-us-block2-link" onClick={() => navigate("/academy")}> ¿Te sumas al movimiento de la excelencia?</p>
                </div>
            </div>
            <div className="about-us-block3">
                <div className="block3-img">
                    <img src={img3} />
                </div>
                <div className="block3-text">
                    <h2 className="block-title">¿PORQUÉ LO HACEMOS?</h2>
                    <p className="block-paragrah"> Queremos compartir nuestro conocimiento con el mundo y formar a profesionales empoderados con la capacidad de liderar equipos en contextos complejos, a través de la formación de Scrum Masters en nuestra academia donde podrán certificar su aptitud con exámenes de certificación internacional en gerencia de proyectos y metodologías ágiles aplicables a todas las áreas de conocimiento.</p>
                    <p className="block-paragrah">Trabajamos para potenciar tus habilidades y lograr una mejor versión de ti, trabajamos por ofrecer a las organizaciones líderes en agilidad capaces de guiar hacia la consecución de resultados de valor para la organización, stakeholders y sus usuarios.</p>
                </div>
            </div>

            <div className="about-us-block4">
                <div className="block4-text">
                    <h2 className="block-title title-block4">NUESTRO EQUIPO</h2>
                    <p className="block4-paragrah"> Nuestro equipo de profesionales, con <label className="company-highlights">experiencia promedio en el rol de mas de 8 años</label>; cuentan con las perspectivas de las diferentes industrias donde la agilidad es utilizada, admnistrando presupuestos superiores a <label className="company-highlights">1 Millón de Dólares</label> alrededor del mundo.</p>
                </div>
                <div className="block4-staff-cards">
                    <div className="block4-staff-cards-info">
                        <div className="block4-staff-card">
                            <img src={JesusImg} className="staff-card-img"/>
                            <p className="staff-card-name">Jesus Martheyn</p>
                        </div>
                        <div className="block4-staff-card">
                            <img src={CamilaImg} className="staff-card-img"/>
                            <p className="staff-card-name">Camila Ayola</p>
                        </div>
                    </div>
                    <div className="block4-staff-cards-origami">
                        <img src={dinoImg} className="block4-orginame-image" alt="orgiami image of a dinosaur"/>
                    </div>
                    
                </div>

            </div>
        </section>

    );
}

export {AboutUsContent}