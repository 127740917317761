import React from "react";

import { Navbar } from "../../components/Navbar";
import { BannerRoutes } from "../../components/BannerRoutes/BannerRoutes";
import { SectionTitle } from "./../../components/SectionTitle";
import { CareerRouteCarousel } from "../../components/CareerRoutes/CareerRouteCarousel";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { WhatsAppButton } from "../../components/WhatsAppButtom";

function TPM () {
    const btn_whatsapp = ()=> {
        window.open('https://wa.me/+573241854519?text=Hola, he sido redirigido desde agilexcellence.io, y tengo algunas dudas por resolver.', '_blank');
    };

    return (
        <div>
            <Navbar/>
            <BannerRoutes/>
            <SectionTitle
                BoldText= "Cursos"
                ligthText=" de esta ruta"
            />
            <CareerRouteCarousel
                name= "Agile Mindset"
                description = "Aprende técnicas, habilidades y prácticas que te harán un experto en agilidad"
                competency = "Agile"
            />

            <CareerRouteCarousel
                name= "Facilitation Skills"
                description = "Aprende técnicas y prácticas que te harán un experto en facilitación de espacios colaborativos"
                competency = "Facilitation"
            />

            <CareerRouteCarousel
                name= "Technical Skills"
                description = "Aprende conceptos y practicas técnicas del end-to-end del desarrollo de un producto de software"
                routeIdentifier = "TPM"
                competency = "Technical"
            />

            <CareerRouteCarousel
                name= "Product Management"
                description = "Aprende conceptos y practicas para la definición de un producto de software de valor centrados en el usuario"
                routeIdentifier = "TPM"
                competency = "Product"
            />
             <div className="course-more-info-block">
                <h2 className="course-more-info-text">¿Aún tienes dudas por resolver?</h2>
                <button className="course-more-info-btn" onClick={btn_whatsapp}>¡Chatea con nosotros!</button>
            </div>

            <Footer/>
            <ScrollTop/>
            <WhatsAppButton/>
        </div>
       
    );
}

export {TPM};