import React, {useEffect} from "react";
import { Navbar } from "./../components/Navbar";
import { Banner } from "./../components/Banner";
import { ServicesSlider } from "../components/ServicesSlider";
import { WhatsAppButton } from "./../components/WhatsAppButtom";
import { Testimonials } from "./../components/Testimonials";
import { Footer } from "./../components/Footer";
import { ScrollTop } from "./../components/ScrollTop";
import { ToogleMenu } from "./../components/ToogleMenu";
import { ChatBot } from "./../components/ChatBot";
import { LanguageToogle } from "./../components/LanguageToogle";
import { CourseCardView } from "../components/CourseCardView";
import { BusinessMetrics } from "../components/BusinessMetrics/BusinessMetrics";

import useWebMetrics from "./../hooks/useWebMetrics";
import { CompaniesCarousel } from "../components/CompaniesCarousel/CompaniesCarousel";

// import "./../styles/App.css";
// import "./../styles/chatbot.css";

function Home() {

  const {validUserId, setvalidUserId} = useWebMetrics();

  return (
    <React.Fragment>
      <Navbar/>
      <Banner/>
      <CourseCardView/>
      <ServicesSlider/>
      <BusinessMetrics/>
      <Testimonials/>
      <CompaniesCarousel/>
      <Footer/>
      <WhatsAppButton/>
      <ScrollTop/>
      {/* <ToogleMenu/>  */}
      {/* <ChatBot/> */}
    </React.Fragment>
  );
}

export default Home;
