import React from "react";
import "./../../styles/infiniteCarouselView.css"

import PSMIAgo22 from "./../../imgs/InfiniteCarousel/PSMI - Agosto 2022.webp";
import PSMIJun2023 from "./../../imgs/InfiniteCarousel/PSMI - Junio2023.webp";
import PSMIFeb23 from "./../../imgs/InfiniteCarousel/PSMI - Febrero 2022.webp";

import { InfiniteImageCarousel} from "./InfiniteImageCarousel";
import { InfiniteImageCarouselReverse } from "./InfiniteImageCarouselReverse";

function InfiniteCarouselView () {

    return (
        <div className="infinite-carousel-view-main-container">
            <div className="infinite-carousel-view-block">
                <InfiniteImageCarousel
                    img1={PSMIAgo22}
                    img2={PSMIJun2023}
                    img3={PSMIFeb23}
                    img4={PSMIJun2023}
                />
            </div>
            {/* <div className="infinite-carousel-view-block">
                <InfiniteImageCarouselReverse
                    img1={PSMIAgo22}
                    img2={PSMIJun2023}
                    img3={PSMIAgo22}
                    img4={PSMIJun2023}
                />
            </div> */}
        </div>
            
    );

}

export {InfiniteCarouselView};