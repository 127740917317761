import React from "react";
import { useParams } from "react-router-dom";

import "./../../styles/scrumMaster.css"
import { CoursePresentation } from "../../components/CoursePresentation";
import {Navbar} from "../../components/Navbar";
import { WhatsAppButton } from "../../components/WhatsAppButtom";
import { Testimonials } from "../../components/Testimonials";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";
import { ToogleMenu } from "../../components/ToogleMenu";
import { ChatBot } from "../../components/ChatBot";

import historyIcon from "./../../imgs/CourseIcons/1.png";
import rolesIcon from "./../../imgs/CourseIcons/2.png";
import principlesIcon from "./../../imgs/CourseIcons/3.png";
import valuesIcon from "./../../imgs/CourseIcons/4.png";
import eventsIcon from "./../../imgs/CourseIcons/5.png";
import artefactsIcon from "./../../imgs/CourseIcons/6.png";

import { InfiniteCarouselView } from "../../components/InfiniteCarousel/InfiniteCarouselView";


import topicsImg from "./../../imgs/temarios1.jpg";

function IntroduccionProyectos () {
    
    let learningCourseId = useParams();
    
    return (
        <React.Fragment>
            <Navbar/>
            <div className="scrum-master-main-container">
                <CoursePresentation
                courseName="CURSO INTRODUCCIÓN A LA GESTIÓN DE PROYECTOS"
                courseDescription="¡Bienvenido al curso completo de Jira que te llevará desde los conceptos básicos hasta la automatización avanzada! Diseñado para proporcionarte un conocimiento exhaustivo de Jira, este curso abarca todos los aspectos esenciales, desde los fundamentos hasta las funciones avanzadas."
                learningCourseId= {learningCourseId}
                videoUrl= "6pUrK_ZdwIg"
                />
                <div className="course-goals-block">
                    <h1 className="course-goals-title">¿Qué aprenderás?</h1>
                    <div className="course-goals-content">

                        <div className="course-goals-line1">

                            <div className="course-goal-item">
                                <img src={historyIcon} className="course-item-icon" alt="hostory icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Fundamentos</h2>
                                    <p className="goal-item-description"> Aprenderás la terminología clave y comprenderás la estructura de Jira, desde proyectos hasta tareas y epics.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={rolesIcon} className="course-item-icon" alt="roles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Configuración</h2>
                                    <p className="goal-item-description"> Te guiaremos paso a paso en la creación y configuración de proyectos, adaptándolos a las necesidades específicas de tu equipo y proyecto.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={principlesIcon} className="course-item-icon" alt="principles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Personalización</h2>
                                    <p className="goal-item-description"> Profundizarás en la personalización de flujos de trabajo, tableros y esquemas, permitiéndote adaptar Jira a tus procesos únicos.</p>
                                </div>
                            </div>

                        </div>

                        <div className="course-goals-line2">
                           
                            <div className="course-goal-item">
                                <img src={valuesIcon} className="course-item-icon" alt="values icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Reportes</h2>
                                    <p className="goal-item-description"> Descubre cómo generar informes personalizados para obtener insights significativos sobre el progreso del proyecto y la productividad del equipo.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={eventsIcon} className="course-item-icon" alt="events icon" />
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Automatización</h2>
                                    <p className="goal-item-description"> Explorarás las capacidades de automatización de Jira, aprendiendo a simplificar y acelerar tareas repetitivas para mejorar la eficiencia del equipo.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={artefactsIcon} className="course-item-icon" alt="artefacts icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Plugins</h2>
                                    <p className="goal-item-description"> Descubre el marketplace de Plugins útiles para tus proyectos scrum, kanban, de mantimiento, descubrimiento de productos y más.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="course-topics-block">
                    <h1 className="course-topics-title">Temario</h1>
                    <div className="course-topics-container">
                        <div className="course-topics-description">
                            <p className="course-topics-text">Somos conscientes de los rápidos cambios en el entorno empresarial y tecnológico, por ello estamos preparados para afrontar estos retos formando a los futuros <label className="company-highlights">Scrum Masters con las competencias, técnicas y herramientas</label> que les permitan guiar equipos de alto rendimiento a la entrega continua de valor, a través de un nivel de entendimiento a detalle de Jira como la herramienta para la gestión end-to-end de tus proyectos de desarrollo productos de tecnología.</p>
                        </div>
                        <div className="course-topics-content">
                            <img src= {topicsImg} className="course-topics-img" alt="topics img"/>
                            <div className="course-topics-bullets">
                                <h2 className="course-topics-subtitle">Contenido del Curso</h2>
                                <div className="course-topics-bullets-container">
                                    <p className="course-topics-text">Este curso es ideal para desarrolladores, gerentes de proyecto y cualquier persona interesada en aprovechar al máximo Jira para la gestión de proyectos ágiles. El curso abordará el eje temático detallado a continuación:</p>
                                    <ul className="course-topics-list-container">
                                        <li className="course-topic-item">Introducción a Jira</li>
                                        <li className="course-topic-item">Elementos de Jira</li>
                                        <li className="course-topic-item">Creación de un proyecto</li>
                                        <li className="course-topic-item">Filtros & JQL</li>
                                        <li className="course-topic-item">Dashboards</li>
                                        <li className="course-topic-item">Perzonalización de un proyecto</li>
                                        <li className="course-topic-item">Automatizaciones</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="course-included-block">
                <h1 className="course-included-title">¿Qué obtendrás al inscribirte?</h1>
                <p className="course-included-text">En <label className="company-name-label"> agilexcellence</label> constantemente reinventamos la experiencia de nuestros cursos, grantizando que aprendas de las experiencias en diversos sectores de la industria de nuestros asistentes y entrenadores. 
                Aparte de nuestra garantía de no prepararte solo para aprobar un examen, al inscribirte en este curso obtendrás:</p>
                <ul className="course-included-list-container">
                    <li className="course-included-item">16 horas de formación a través de Zoom</li>
                    <li className="course-included-item">Material complementario de estudio</li>
                    <li className="course-included-item">Sesiones de simulación de examen de certificación en Atlassian University</li>
                    <li className="course-included-item">Recomendaciones para la correcta implementación de Jira en tu organización</li>
                    <li className="course-included-item">Emisión de certificado de asistencia al curso</li>
                    <li className="course-included-item">Grabación de sesiones en vivo y disponibilización en nuestra plataforma</li>
                </ul>
            </div>
            
            <div className="course-community-block">
                <h1 className="course-community-title">Únete a la comunidad</h1>
                <p className="course-community-text">Inicia tu camino como Scrum Master dentro de la comunidad de<label className="company-name-label"> agilexcellence</label>, aprende de las experiencias en diversos sectores de la industria donde Scrum es utilizado.</p>
            </div>
            <InfiniteCarouselView/>
            
            {/* <Testimonials/> */}
            <Footer/>
            <WhatsAppButton/>
            <ScrollTop/>
            {/* <ToogleMenu/> */}
            {/* <ChatBot/> */}
        </React.Fragment>
    );
}

export {IntroduccionProyectos};