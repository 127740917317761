import React from "react";
import "../../styles/careerRouteCard.css";

import PSM1 from "./../../imgs/CourseIcons/SMCaratula.png";
import PSM2 from "./../../imgs/CourseIcons/PSM2Caratula.webp";
import KSD from "./../../imgs/CourseIcons/KSDCaratula.png";
import KSI from "./../../imgs/CourseIcons/KSICaratula.png";
import JIRAF from "./../../imgs/CourseIcons/JiraCaratula.png";
import PMI from "./../../imgs/CourseIcons/PMICaratula.png";
import GDF from "./../../imgs/CourseIcons/GDFCaratula.png";

import { Difficulty } from "../Difficulty";

function CareerRouteCard ({name,courseIdentifier,difficulty}) {
    return (
        <div className="career-route-card-container">
            <div className="career-route-card-img-block">
                <img src= {courseIdentifier === "PSMI" ? PSM1 : courseIdentifier === "KSD"? KSD: courseIdentifier === "JIRAF" ? JIRAF : courseIdentifier === "PMI" ? PMI : courseIdentifier === "KSI" ? KSI : courseIdentifier === "GDF" ? GDF : courseIdentifier === "PSM2" ? PSM2 : ""} className="career-route-card-img" title="course presentation marketing image" alt="course presentation"/>
            </div>
            <h1 className="career-route-card-name"> {name} </h1>
            <Difficulty
                level={difficulty}
            />
            <button className="career-route-card-btn">Próximo Curso</button>
        </div>
    );
}

export {CareerRouteCard};