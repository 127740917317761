import React from "react";
import "./../styles/academyContent.css";

import academyIcon from "./../imgs/AboutUs/shipOrigami.png";
import planeIcon from "./../imgs/AboutUs/plane&CloudOrigami.png";
import bulletIcon from "./../imgs/AboutUs/BinetaOrigami.png";

import plan1Icon from "./../imgs/AboutUs/paperPlaneOrigami.png";
import plan2Icon from "./../imgs/AboutUs/DoveOrigami.png";
import plan3Icon from "./../imgs/AboutUs/DeerOrigami.png";

function AcademyContent () {

    const handleAnualSuscription = () => {
        window.open("https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-5GB17769G9732624CMTQVP2Y","_blank");
    }   

    const handleMonthlySuscription = () => {
        window.open("https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1GX54886A7979620HMTQVO2Q","_blank");
    }   
    

    return (
        <div className="academy-content-main-container">
            <div className="academy-content-container">

                <div className="academy-content-description-container">
                    <p className="academy-content-description">COMPLEMENTA TU FORMACIÓN COMO SCRUM MASTER CON <label className="company-name-label">AGILEXCELLENCE ACADEMY</label>, UN PROGRAMA DISEÑADO PARA TU CRECIMIENTO EN EL ROL.</p>
                    <img src= {academyIcon} className="academy-content-icon"/>
                </div>
                
                <h1 className="academy-pilar-section-title">PROPUESTA DE VALOR</h1>
                <div className="academy-content-pilars-container">
                    <div className="academy-pilar-box">
                        <div className="academy-pilar-box-container">
                            <h1 className="academy-pilar-title">Training</h1>
                            <p className="academy-pilar-description"> Entrenamientos facilitados por <label className="company-highlights">profesionales de la industria</label>, con todo que necesitas para crecer en tu rol como Scrum Master</p>
                        </div>
                    </div>
                    <div className="academy-pilar-box">
                        <div className="academy-pilar-box-container">
                            <h1 className="academy-pilar-title">Mentoría</h1>
                            <p className="academy-pilar-description"> Horas de mentoría con un equipo multidisciplinario que complementan tu entrenamiento con sesiones para compartir sus <label className="company-highlights">lecciones aprendidas</label></p>
                        </div>
                    </div>
                    <div className="academy-pilar-box">
                        <div className="academy-pilar-box-container">
                            <h1 className="academy-pilar-title">Coaching</h1>
                            <p className="academy-pilar-description"> Te apoyamos en tu rol en los <label className="company-highlights">desafíos del día a día</label>, y en tu búsqueda de la próxima oportunidad laboral</p>
                        </div>
                    </div>
                </div>

                <div className="academy-conditions-section-container">
                    <div className="academy-conditions-section-main">
                        <img src= {planeIcon} className="academy-conditions-section-img"/>
                        <div className="academy-conditions-container">
                            <h1 className="academy-conditions-title">CONDICIONES PARA PARTICIPAR</h1>
                            <div className="academy-conditions-bullets">
                                <div className="academy-conditions-bullet">
                                    <img src={bulletIcon} className="academy-conditions-bullet-img"/>
                                    <p className="academy-conditions-bullet-text">Haber cursado el PSMI con nosotros </p>
                                </div>
                                <div className="academy-conditions-bullet">
                                    <img src={bulletIcon} className="academy-conditions-bullet-img"/>
                                    <p className="academy-conditions-bullet-text">No requiere profesión de base </p>
                                </div>
                                <div className="academy-conditions-bullet">
                                    <img src={bulletIcon} className="academy-conditions-bullet-img"/>
                                    <p className="academy-conditions-bullet-text">Acceso a internet y computador con camara </p>
                                </div>
                                <div className="academy-conditions-bullet">
                                    <img src={bulletIcon} className="academy-conditions-bullet-img"/>
                                    <p className="academy-conditions-bullet-text">Disponibilidad de 2 hrs semanales </p>
                                </div>
                                <div className="academy-conditions-bullet">
                                    <img src={bulletIcon} className="academy-conditions-bullet-img"/>
                                    <p className="academy-conditions-bullet-text">Completar documentos para la admisión </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="academy-conditions-btn">Whatsapp Us</button>
                </div>

                <div className="academy-plans-section-container">
                    <h1 className="academy-plans-section-title">PLANES</h1>
                    <p className="academy-plans-section-intro">Selecciona el plan que mejor se adapte a ti</p>
                    <div className="academy-plans-content-container">
                        
                        <div className="academy-plans-content-box">
                            <div className="academy-plans-content-box-header">
                                <div className="academy-plans-box-header-text">
                                    <p className="academy-plan-header-name">Básico</p>
                                    <h2 className="academy-plan-header-cost">50 USD/mes</h2>
                                </div>
                                <img src={plan1Icon} className="academy-plan-header-img"/>
                            </div>

                            <div className="academy-plan-details-container">
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">1 cuenta</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">Cobro mes a mes</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">Acceso ilimitado a clases y material de estudio</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">1 mentoría mensual individual</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">2 horas semanales de formación</p>
                                </div>
                            </div>

                            <button className="academy-plan-detail-btn" onClick={handleMonthlySuscription}>SELECCIONAR PLAN</button>
                        </div>

                        <div className="academy-plans-content-box">
                            <div className="academy-plans-content-box-header">
                                <div className="academy-plans-box-header-text">
                                    <p className="academy-plan-header-name">Avanzado</p>
                                    <h2 className="academy-plan-header-cost">40 USD/mes</h2>
                                </div>
                                <img src={plan2Icon} className="academy-plan-header-img"/>
                            </div>

                            <div className="academy-plan-details-container">
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">1 cuenta</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">Pago por año</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">Acceso ilimitado a clases y material de estudio</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">2 mentorías mensual individual</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">2 horas semanales de formación</p>
                                </div>
                                <div className="academy-plan-detail-item">
                                    <img src={bulletIcon} className="academy-plan-detail-item-bullet-icon"/>
                                    <p className="academy-plan-detail-item-text">Kit de bienvenida (Hoodie y taza)</p>
                                </div>
                            </div>

                            <button className="academy-plan-detail-btn" onClick={handleAnualSuscription}>SELECCIONAR PLAN</button>
                        </div>

                        <div className="academy-plans-content-box">
                            <div className="academy-plans-content-box-header">
                                <div className="academy-plans-box-header-text">
                                    <p className="academy-plan-header-name">Personalizado</p>
                                    <h2 className="academy-plan-header-cost">Contactar</h2>
                                </div>
                                <img src={plan3Icon} className="academy-plan-header-img"/>
                            </div>

                            <div className="academy-plan-details-container">
                                <div className="academy-plan-detail-personalized">
                                    <p className="academy-plan-detail-personalized-text">Todas las caracteristicas de los planes anteriores además de las caracteristicas personalizadas que necesites.</p>
                                    <p className="academy-plan-detail-personalized-text">Se realizará un presupuesto ajustado a las necesidades de tu negocio.</p>
                                </div>
                            </div>

                            <button className="academy-plan-detail-btn">SELECCIONAR PLAN</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export {AcademyContent};