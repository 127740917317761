import React from 'react';
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { ToogleMenu } from "../components/ToogleMenu";
import { ChatBot } from "../components/ChatBot";

function EmbracingSustainability() {
  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages
        pageName = "Embracing Sustainability"
        pageSubtitle = "Reasons for Choosing US"
        pageDescription= "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vero nostrum quis, odio veniam itaque ullam debitis qui magnam consequatur ab. Vero nostrum quis, odio veniam itaque ullam debitis qui magnam consequatur ab."
      />
      <Footer/>
      <ScrollTop/>
      <ToogleMenu/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {EmbracingSustainability};
