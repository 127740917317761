import React from "react";
import "./../../styles/companiesCarousel.scss";

import uniLibre from "../../imgs/CompaniesCarousel/Unilibre.webp";
import uniSinu from "../../imgs/CompaniesCarousel/Unisinu.webp";
import Afinia from "../../imgs/CompaniesCarousel/Afinia.webp";
import Bancolombia from "../../imgs/CompaniesCarousel/Bancolombia.webp";
import Quilmes from "../../imgs/CompaniesCarousel/Quilmes.webp";
import Globant from "../../imgs/CompaniesCarousel/Globant.webp";
import Tecnologico from "../../imgs/CompaniesCarousel/Tecnologico.webp";

function CompaniesCarousel () {

    return (
        <React.Fragment>
            <div class="slider">
                <div class="slide-track">
                    <div class="slide">
                        <img src={Tecnologico} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={uniLibre} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={uniSinu} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Afinia} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Bancolombia} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Quilmes} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Globant}height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Tecnologico} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={uniLibre} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={uniSinu} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Afinia} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Bancolombia} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Quilmes} height="100" width="250" alt="" />
                    </div>
                    <div class="slide">
                        <img src={Globant}height="100" width="250" alt="" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export {CompaniesCarousel};