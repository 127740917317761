import React from "react";
import "./../../styles/infiniteImageCarousel.css";

function InfiniteImageCarousel ({img1,img2,img3,img4}) {
    return (
        <React.Fragment>
            <section className="infinite-carousel-main-container">
            <article className="infinite-carousel-container">
                <div className="infinite-carousel-container-div">
                <ul className="infinite-carousel-img-items">
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img1} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img2} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img3} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img4} /></li>
                    
                </ul>
                </div>
                <div className="infinite-carousel-container-div">
                <ul className="infinite-carousel-img-items">
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img1} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img2} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img3} /></li>
                    <li className="infinite-carousel-img-item"><img className="infinite-carousel-img" src= {img4} /></li>
                </ul>
                </div>
            </article>
            </section>
        </React.Fragment>
    );

}

export {InfiniteImageCarousel};