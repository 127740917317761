import React from "react";
import "../styles/banner.css";

import { useNavigate } from "react-router-dom";

function Banner (){

    const navigate = useNavigate();

    return (
        <div className="banner-main-container">
            <div className="banner-secundary-container">
                <div className="banner-content-container">
                    <h2 className="banner-text-line1">Reach exceptional results</h2>
                    <h1 className="banner-text-line2">EMPOWERING AGILE LEADERS</h1>
                    <div className="banner-btn">
                        <a className="banner-btn1" onClick={() => navigate("/learning-path/TPM/34b6613b-2aeb-49d1-8fc6-8bc43b99edfa")}>RUTA DE APRENDIZAJE</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {Banner};