import React from "react";
import { ToogleContext } from "../hooks/ToogleContext";
import "../styles/toogleMenu.css";
import icon from "./../imgs/cideBot.svg";

function ToogleMenu (){

    const {openToogleMenu, setopenToogleMenu, openChatBot, setopenChatBot} = React.useContext(ToogleContext);

    const toggleButtom = () => {
        setopenToogleMenu(!openToogleMenu);
    };

    const chatbotButtom = () => {
        setopenChatBot(!openChatBot);
    };

    const mailButtom = () => {
        window.open('mailto:info@cidelac.com', '_blank');
    }

    const wappButtom = () => {
        window.open('https://wa.me/+573245006792', '_blank');
    }

    return (
        <div className={`menu ${openToogleMenu && "open"} `} onClick={ toggleButtom }>
            <div className="button" onClick = {chatbotButtom}> </div> 
            <div className="button" onClick = {wappButtom}></div>
            <div className="button" onClick={mailButtom}></div>
        </div>
    );
}

export {ToogleMenu};