import React, {useState, useEffect} from "react";
import "./../../styles/scrumMaster.css";
import "./../../styles/pricingOptions.scss";

import { useParams } from "react-router-dom";
import { getGraphQl } from "../../hooks/ApiCalls";

import { CoursePresentation } from "../../components/CoursePresentation";
import {Navbar} from "../../components/Navbar";
import { WhatsAppButton } from "../../components/WhatsAppButtom";
import { Footer } from "../../components/Footer";
import { ScrollTop } from "../../components/ScrollTop";

import topicsImg from "./../../imgs/temarios1.jpg";
import historyIcon from "./../../imgs/CourseIcons/1.png";
import rolesIcon from "./../../imgs/CourseIcons/2.png";
import principlesIcon from "./../../imgs/CourseIcons/3.png";
import valuesIcon from "./../../imgs/CourseIcons/4.png";
import eventsIcon from "./../../imgs/CourseIcons/5.png";
import artefactsIcon from "./../../imgs/CourseIcons/6.png";

import PSM2certificate from "./../../imgs/certificateofcompletionPSM2.png";
import PSM2badge from "./../../imgs/CourseIcons/psm2Badge.png";

import { InfiniteCarouselView } from "../../components/InfiniteCarousel/InfiniteCarouselView";
import { TrainerProfile } from "../../components/TrainerProfile";


function ScrumMasterPSM2 () {
    
    let learningCourseId = useParams();
    const [coursePrice, setcoursePrice] = useState("");

    const btn_whatsapp = ()=> {
        window.open('https://wa.me/+573241854519?text=Hola, he sido redirigido desde agilexcellence.io, y tengo algunas dudas por resolver.', '_blank');
    };
    
    let graphQuery = `
        query {
            GetProductInfoByLearningCourseId(ProductInfo:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
                price
                name
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetProductInfoByLearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setcoursePrice(GetProductInfoByLearningCourseId);
        })();

      }, []);

    return (
        <React.Fragment>
            <Navbar/>
            <div className="scrum-master-main-container">
                <CoursePresentation
                courseName="PROFESSIONAL SCRUM MASTER PSM2"
                courseDescription="Aprende las prácticas, herramientas y estancias que te harán ser un Scrum Master más efectivo. Aprende las formas en las que puedes ayudar a cambiar el entorno de los Scrum Teams de tu organización para ser cada día más exitosos y ágiles, lidiando los conflictos e impedimentos hacia el camino de equipos y organizaciones orientadas a la entrega de valor, ¡además obtendrás tu certificación internacional otorgada por Scrum.org!"
                learningCourseId= {learningCourseId}
                videoUrl= "bv_DlrXRjtQ"
                level="Intermedio"
                />
                <div className="course-goals-block">
                    <h1 className="course-goals-title">¿Qué aprenderás?</h1>
                    <div className="course-goals-content">

                        <div className="course-goals-line1">

                            <div className="course-goal-item">
                                <img src={historyIcon} className="course-item-icon" alt="hostory icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Historia</h2>
                                    <p className="goal-item-description"> Conoce quienes han contribuido a la creación de las distintas metodologías ágiles y qué dió origen a la agilidad.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={rolesIcon} className="course-item-icon" alt="roles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Roles</h2>
                                    <p className="goal-item-description"> Conoce los diferentes roles existentes en Scrum, sus responsabilidades y niveles de madurez.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={principlesIcon} className="course-item-icon" alt="principles icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Principios</h2>
                                    <p className="goal-item-description"> Conoce los principios bajo los cuales trabaja Scrum y cada uno de los miembros del equipo, exploraremos el Agile Manifesto.</p>
                                </div>
                            </div>

                        </div>

                        <div className="course-goals-line2">
                           
                            <div className="course-goal-item">
                                <img src={valuesIcon} className="course-item-icon" alt="values icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Valores</h2>
                                    <p className="goal-item-description"> Conoce los valores de Scrum y cómo vivirlos en el trabajo del día a día, así como su evolución en madurez.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={eventsIcon} className="course-item-icon" alt="events icon" />
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Eventos</h2>
                                    <p className="goal-item-description"> Conoceremos los diferentes evetos de Scrum, su propósito, cómo facilitarlo y herramientas que debe conocer todo Scrum Master.</p>
                                </div>
                            </div>

                            <div className="course-goal-item">
                                <img src={artefactsIcon} className="course-item-icon" alt="artefacts icon"/>
                                <div className="course-item-text">
                                    <h2 className="goal-item-title">Artefactos</h2>
                                    <p className="goal-item-description"> Conoceremos y profundirizaremos en el entendimiento de los artefactos de Scrum, así como buenas prácticas y patrones que debes poder identificar.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="course-topics-block">
                    <h1 className="course-topics-title">Temario</h1>
                    <div className="course-topics-container">
                        <div className="course-topics-description">
                            <p className="course-topics-text">Somos conscientes de los rápidos cambios en el entorno empresarial y tecnológico, por ello estamos preparados para afrontar estos retos formando a los futuros <label className="company-highlights">Scrum Masters con las competencias, técnicas y herramientas</label> que les permitan guiar equipos de alto rendimiento a la entrega continua de valor, a través de un nivel de entendimiento a detalle del framework Scrum.</p>
                        </div>
                        <div className="course-topics-content">
                            <img src= {topicsImg} className="course-topics-img" alt="topics img"/>
                            <div className="course-topics-bullets">
                                <h2 className="course-topics-subtitle">Contenido del Curso</h2>
                                <div className="course-topics-bullets-container">
                                    <p className="course-topics-text">El curso de preparación a la certificación PSM II, es el curso intermedio para Scrum Masters practicantes y nuevos candidatos a certificar sus conocimientos del marco ágil de Scrum de acuerdo a la <label className="company-highlights">Scrum Guide 2020</label>. El curso abordará el eje temático detallado a continuación:</p>
                                    <ul className="course-topics-list-container">
                                        <li className="course-topic-item">Agile Manifesto</li>
                                        <li className="course-topic-item">Framework de Scrum</li>
                                        <li className="course-topic-item">Product Backlog Management</li>
                                        <li className="course-topic-item">Herramientas para Facilitación</li>
                                        <li className="course-topic-item">Herramientas para Product Discovery</li>
                                        <li className="course-topic-item">Herramientas para Team Engagement</li>
                                        <li className="course-topic-item">Nexus Framework</li>
                                        <li className="course-topic-item">Evidence-Based Management - EBM</li>
                                        <li className="course-topic-item">Ruta de crecimiento del Scrum Master</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="course-included-block">
                <h1 className="course-included-title">¿Qué obtendrás al inscribirte?</h1>
                <p className="course-included-text">En <label className="company-name-label"> agilexcellence</label> constantemente reinventamos la experiencia de nuestros cursos, grantizando que aprendas de las experiencias en diversos sectores de la industria de nuestros asistentes y entrenadores. 
                Aparte de nuestra garantía de no prepararte solo para aprobar un examen, al inscribirte en este curso obtendrás:</p>
                <div className="course-included-list-certificate-container">
                    <ul className="course-included-list-container">
                        <li className="course-included-item">23 horas de formación a través de Zoom</li>
                        <li className="course-included-item">Material complementario de estudio</li>
                        <li className="course-included-item">Sesiones de simulación de examen de certificación</li>
                        <li className="course-included-item">Examen de certificación como Professional Scrum Master <label className="company-name-label"> (PSM2)</label> de scrum.org</li>
                        <li className="course-included-item">Ruta personalizada de crecimiento como líder ágil</li>
                        <li className="course-included-item">Emisión de certificado de asistencia al curso</li>
                        <li className="course-included-item">Grabación de sesiones en vivo y disponibilización en nuestra plataforma</li>
                    </ul>
                    <img src={PSM2certificate} className="course-included-certificate-img" alt="sample of agilexcellence certificate of completion"/>
                </div>
            </div>

            <div className="course-certification-about-block">
                <h1 className="course-certification-about-title">¿Qué es la certificación como Scrum Professional PSM2?</h1>
                <div className="course-certification-about-content">
                <a className="course-certification-external-link" href="https://www.scrum.org/assessments/professional-scrum-master-ii-certification" target="__blank"><img  className="course-certification-about-badge" src={PSM2badge} alt="certification issued badge example"/></a>
                    <ul className="course-included-list-container">
                    <li className="course-included-item"> Requiere aprobar el examen de certificación como Professional Scrum Master <label className="company-name-label"> (PSM2)</label> de scrum.org</li>
                        <li className="course-included-item">Es una certificación internacionalmente reconocida, emitida por scrum.org</li>
                        <li className="course-included-item">Es la certificación intermedia en el camino a ser un Scrum Master experto</li>
                        <li className="course-included-item">Es una certificación vitalicia, no requiere pagar anualidad ni recertificación</li>
                        <li className="course-included-item">Requiere aprobar un examen en modalidad online con el 85% de respuestas correctas</li>
                        <li className="course-included-item">Tiene un tiempo límite de 90 minutos para responder el examen</li>
                        <li className="course-included-item">El examen consiste de 30 preguntas de selección multiple, única respuesta y falso o verdadero</li>
                    </ul>
                </div>
            </div>

            <div className="course-pricing-options-main-container">
                <h1 className="course-pricing-options-title">Selecciona la opción que mas se acomode a lo que buscas</h1>
                <p className="course-pricing-options-subtitle">Estás a pocos clics de iniciar tu formación con agilexcellence y acceder a nuestros recursos.</p>
                <div class="course-pricing-cards-container">
                    <div class="course-pricing-plan-card">
                        <h2 className="course-pricing-plan-title">Individual</h2>
                        <div class="price">USD {coursePrice.price}</div>
                        <ul class="features">
                            <li><i class="fas fa-check-circle"></i> 1 Asiento al Curso</li>
                            <li><i class="fas fa-check-circle"></i> 1 Voucher Examen PSM2</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Portal Estudiantes</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Simulador</li>
                            <li><i class="fas fa-times-circle"></i> No Descuento</li>
                        </ul>
                        <button className="course-pricing-btn" onClick={btn_whatsapp}>Conocer formas de pago</button>
                    </div>
                    <div class="course-pricing-plan-card popular">
                        <span>Más Popular</span>
                        <h2 className="course-pricing-plan-title">Grupos</h2>
                        <div class="price">Custom Pricing</div>
                        <ul class="features">
                            <li><i class="fas fa-check-circle"></i> Precio Personalizado</li>
                            <li><i class="fas fa-check-circle"></i> Apartir de 3 Personas</li>
                            <li><i class="fas fa-check-circle"></i> Voucher Examen PSM2 Acordados</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Portal Estudiantes</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Simulador</li>
                            <li><i class="fas fa-check-circle"></i> Descuento por Grupos</li>
                        </ul>
                        <button className="course-pricing-btn" onClick={btn_whatsapp}>Contactanos</button>
                    </div>
                    <div class="course-pricing-plan-card">
                        <h2 className="course-pricing-plan-title">Empresas</h2>
                        <div class="price">Custom Pricing</div>
                        <ul class="features">
                            <li><i class="fas fa-check-circle"></i> Usuarios Ilimitados</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Portal Estudiantes</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Simuladores</li>
                            <li><i class="fas fa-check-circle"></i> Acceso Dashboard Empresa</li>
                            <li><i class="fas fa-check-circle"></i> Descuento Dispomible</li>
                        </ul>
                        <button className="course-pricing-btn" onClick={btn_whatsapp}>Contactanos</button>
                    </div>
                </div>
            </div>

            <div className="course-more-info-block">
                <h1 className="course-more-info-text">¿Aún tienes dudas por resolver?</h1>
                <button className="course-more-info-btn" onClick={btn_whatsapp}>¡Chatea con nosotros!</button>
            </div>

            <div className="course-trainer-info-block">
                <h1 className="course-trainer-info-title">Conoce quién será tu entrenador en el curso {coursePrice.name}</h1>
                <TrainerProfile/>
            </div>
            
            <div className="course-community-block">
                <h1 className="course-community-title">Únete a la comunidad</h1>
                <p className="course-community-text">Inicia tu camino como Scrum Master dentro de la comunidad de<label className="company-name-label"> agilexcellence</label>, aprende de las experiencias en diversos sectores de la industria donde Scrum es utilizado.</p>
            </div>
            <InfiniteCarouselView/>
            
            {/* <Testimonials/> */}
            <Footer/>
            <WhatsAppButton/>
            <ScrollTop/>
            {/* <ToogleMenu/> */}
            {/* <ChatBot/> */}
        </React.Fragment>
    );
}

export {ScrumMasterPSM2};