import React, { useState }  from "react";
import "./../styles/servicesSlider.css"

import { SectionTitle } from "./SectionTitle";
import backIcon from "./../imgs/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg";
import nextIcon from "./../imgs/arrow_forward_ios_FILL0_wght400_GRAD0_opsz48.svg";
import radioIncon from "./../imgs/radio_button_checked_FILL0_wght400_GRAD0_opsz48.svg";
import radioUncheckIcon from "./../imgs/radio_button_unchecked_FILL0_wght400_GRAD0_opsz48.svg";

import slide1Icon from "./../imgs/AboutUs/flowerOrigami.png";
import slide2Icon from "./../imgs/AboutUs/aireOrigami.png";
import slide3Icon from "./../imgs/AboutUs/frogOrigami.png";
import slide4Icon from "./../imgs/AboutUs/shipOrigami.png";

function ServicesSlider () {

    const [slideNumber, setslideNumber] = useState(0);
    const [slideState, setslideState] = useState([true,false,false,false]);

    const Slideback = () => {
        let auxArr= slideState;

        if (slideNumber == 0){
            auxArr[slideNumber]=false;
            auxArr[slideState.length -1]=true;
            setslideState(auxArr);
            setslideNumber(slideState.length -1);
        } 
        else {
            auxArr[slideNumber]=false;
            auxArr[slideNumber -1]=true;
            setslideNumber(slideNumber -1);
        }
    };

    const Slidenext = () => {
        
        let auxArr= slideState;

        if(slideNumber == slideState.length -1){
            auxArr[slideNumber]=false;
            auxArr[0]=true;
            setslideNumber(0);
        }
        else {
            auxArr[slideNumber]=false;
            auxArr[slideNumber+1]=true;
            setslideNumber(slideNumber +1);
        }
    };

    const HandleChangeStep = (number) => {
        let auxArr = slideState;
        auxArr.fill(false);
        auxArr[number]= true;
        setslideState(auxArr);
        setslideNumber(number);
    }

    
    return(
        <React.Fragment>
            <SectionTitle
            BoldText="Our"
            ligthText=" Portfolio"
            />
            <div className="services-slider-main-container">
                <div className="services-slider-content-container">
                    <img src={backIcon} alt="back icon in services slider" className="services-slider-icon-back" onClick={Slideback}/>

                    <div className={`services-slider-slide ${!slideState[0] && "slide-unactive"}`}>
                        <img src= {slide1Icon} className="services-slider-slide-img"/>
                        <h2 className="services-slider-slide-title">Staff Augmentation</h2>
                        <p className="services-slider-slide-text">Sumamos expertos a tus equipos y organizaciones para acompañarte a transitar tus procesos de transformación de forma efectiva y con un Roadmap a medida</p>
                    </div>

                    <div className={` services-slider-slide ${!slideState[1] && "slide-unactive"}`}>
                        <img src= {slide2Icon} className="services-slider-slide-img"/>
                        <h2 className="services-slider-slide-title">Consultoría</h2>
                        <p className="services-slider-slide-text">Acompañamos organizaciones en la identificación de aspectos de mejora en sus equipos y la transformación a una organización orientada por la entrega de valor continua y frecuente</p>
                    </div>

                    <div className={`services-slider-slide ${!slideState[2] && "slide-unactive"}`}>
                        <img src= {slide3Icon} className="services-slider-slide-img"/>
                        <h2 className="services-slider-slide-title">Training</h2>
                        <p className="services-slider-slide-text">Formamos profesionales en temas relacionados de la agilidad para que además de aprobar examenes de certificación internacional puedan actuar como líderes ágiles de sus organizaciones</p>
                    </div>

                    <div className={`services-slider-slide ${!slideState[3] && "slide-unactive"}`}>
                        <img src= {slide4Icon} className="services-slider-slide-img"/>
                        <h2 className="services-slider-slide-title">Academy</h2>
                        <p className="services-slider-slide-text">Formamos profesionales en diversas disciplinas, con o sin experiencia en tecnología; para que puedan lograr su objetivo de convertirse en futuros Scrum Masters</p>
                    </div>

                    <img src={nextIcon} alt="next icon in services slider" className="services-slider-icon-next" onClick={Slidenext}/>
                </div>
                <div className="services-slider-slide-stepper">
                    <img src= {slideNumber === 0 ? radioIncon : radioUncheckIcon } className="services-slider-slide-step" onClick={()=> HandleChangeStep(0)}/>
                    <img src= {slideNumber === 1 ? radioIncon : radioUncheckIcon } className="services-slider-slide-step" onClick={()=> HandleChangeStep(1)} />
                    <img src= {slideNumber === 2 ? radioIncon : radioUncheckIcon } className="services-slider-slide-step" onClick={()=> HandleChangeStep(2)}/>
                    <img src= {slideNumber === 3 ? radioIncon : radioUncheckIcon } className="services-slider-slide-step" onClick={()=> HandleChangeStep(3)}/>
                </div>
            </div>    
        </React.Fragment>
    );
}

export {ServicesSlider};