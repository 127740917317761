import React from "react";
import {Navbar} from "./../../components/Navbar";

function Blog1 () {
    return (
        <React.Fragment>
        <Navbar/>
        <di>
            <br/>
            <br/>
            <br/>
            <h1>How to effectively use the Moving Motivators in my team?</h1>

            <p>"Moving Motivators" is one of the practices from Management 3.0 that will allow you to understand your team's personal motivations. This isn't just so that, in the role of a servant leader, you can properly guide career plans, work assignments, change management, or even behavior comprehension. Additionally, it's a tool that will enable us to gauge the impact of our decisions on the team's motivations.</p>
            <p>As Scrum Masters, facilitators, or Agile Coaches, we daily act as an intermediate layer between what the company's management expects from our team and the team itself. Being this intermediary layer, we are constantly challenged when trying to communicate organizational decisions to the teams, especially when these might not be well-received or when, as a result of reflection, we identify an improvement opportunity. Our task isn't limited to merely communicating decisions or embarking on change experiments; it also involves keeping the teams energized throughout these processes.</p>
            <p>While it's true that resistance to change is a natural human behavior that instinctively seeks to preserve the way things are done, as leaders, we know that doing the same thing over and over again while expecting different results is, in fact, a declaration of insanity.</p>
            <p>Moving Motivators are based on the idea that individuals have at least ten intrinsic desires that can generate their own motivation. In other words, it's a motivation that starts from the individual themselves without any external intervention that could lead to extrinsic motivation based on rewards or incentives.</p>
            <p>In my experience, I've come to the conclusion that extrinsic motivation can have an immediate and positive effect on team behavior. This type of motivation can shape our ideas for change or experimentation with little resistance. However, this kind of motivation doesn't last over time and creates the mistaken impression of motivation, adoption, and transformation of behaviors.</p>
            <p>On the other hand, intrinsic motivation is more complex to develop, mainly because you must start by individually identifying what motivates your team. But wait, if you think identifying individual motivations is complex, wait until you see how complex it is for each member of your team to discover it.</p>
            <p>In my experience, what can be most challenging is finding the right moment and dynamic that allows for minimal resistance and, at the same time, maximum participation.</p>
        </di>
        </React.Fragment>
    );
}

export {Blog1};