import React from "react";
import { Navbar } from "../components/Navbar";
import { BannerPages } from "../components/BannerPages";
import { Footer } from "../components/Footer";
import { ScrollTop } from "../components/ScrollTop";
import { ToogleMenu } from "../components/ToogleMenu";
import { ChatBot } from "../components/ChatBot";
import { CertificatesContent } from "../components/CerticatesModule/CertificatesContent";

import useWebMetrics from "./../hooks/useWebMetrics";

function CertificatesValidator () {

    const {validUserId, setvalidUserId} = useWebMetrics();

    return (
        <React.Fragment>
            <Navbar/>
            <BannerPages/>
            <CertificatesContent/>
            <Footer/>
            <ScrollTop/>
            {/* <ToogleMenu/>
            <ChatBot/> */}
        </React.Fragment>
    );
}

export {CertificatesValidator};
