import React from "react";
import {useEffect, useState} from "react";
import "./../styles/courseCardView.css"

import { getGraphQl } from "../hooks/ApiCalls";
import { CourseCard } from "./CourseCard";
import { ToogleContext } from "../hooks/ToogleContext";
import { SectionTitle } from "./SectionTitle";

function CourseCardView () {

    const {courseList, setcourseList} = React.useContext(ToogleContext);
    
    let graphQuery = `
        query{
            GetOpenInscriptionCourses {
            courseIdentifier
            name
            productId
            learningCourseId
            startDate
            endDate
            enabled
            } 
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetOpenInscriptionCourses}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setcourseList(GetOpenInscriptionCourses);
        })();

      }, []);

    return(
        <div className="course-cards-main-container">
            <SectionTitle
            BoldText= "Próximos"
            ligthText= " Cursos"
            />   
            <div className="course-cards-wrap">
              
                {    
                    courseList.map( (course) => ( 
                        <CourseCard 
                            key={course.id} 
                            name = {course.name} 
                            startDate = {course.startDate} 
                            endDate={course.endDate}
                            learningCourseId={course.learningCourseId}
                            courseIdentifier={course.courseIdentifier}
                        /> 
                    ))
                }
            </div>
        </div>
    );
}

export {CourseCardView};