import logo from "../imgs/Brand/logo-white.webp";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/footer.css";

function Footer() {

    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="grupo-1">
                <div className="footer-block-left">
                    <figure className="footer-block-left-figure">
                        <a onClick={() => navigate("/")}>
                            <img src={logo} alt="logo agilexcellence white" className="logo-footer" />
                        </a>
                    </figure>
                </div>
                <div className="footer-block-center">
                    <h2 className="footer-block-title">Our Company</h2>
                    <div className="corporate-links">
                        <ul className="corporate-link"><a onClick={() => navigate("/")} >Our Strategy</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")} >Embracing Sustainability</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")} >Corporate Social Responsability</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")} >Privacy Statement</a></ul>
                    </div>
                </div>
                <div className="footer-block-right">
                    <h2 className="footer-block-title">Careers</h2>
                    <div className="corporate-links">
                        <ul className="corporate-link"><a onClick={() => navigate("/")}>Life at agilexcellence</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")}>Open Positions</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")}>FAQ</a></ul>
                    </div>
                </div>
                <div className="footer-block-right">
                    <h2 className="footer-block-title">Resources</h2>
                    <div className="corporate-links">
                        <ul className="corporate-link"><a href="https://blog.agilexcellence.io/" target="__blank">Blogs</a></ul>
                        <ul className="corporate-link"><a href="https://quizapp.agilexcellence.io/" target="__blank">Simulators</a></ul>
                        <ul className="corporate-link"><a href="https://scrumpoker.agilexcellence.io/#/new-game" target="__blank">Ax ScrumPoker</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/certificates")}>Certificate Checker</a></ul>
                        <ul className="corporate-link"><a href="https://www.scrum.org/" target="__blank">scrum.org</a></ul>
                        {/* <ul className="corporate-link"><a onClick={() => navigate("/")}>Open Positions</a></ul>
                        <ul className="corporate-link"><a onClick={() => navigate("/")}>FAQ</a></ul> */}
                    </div>
                </div>
                <div className="box">
                    <h2 className="footer-block-title">Follow Us</h2>
                    <div className="social-network">
                        {/* <a href="#" className="fa fa-twitter" target="_blank"></a> */}
                        <a href="https://www.instagram.com/agilexcellence/" className="fa fa-instagram" target="_blank"></a>
                        <a href="https://www.linkedin.com/company/agilexcellence/" className="fa fa-linkedin" target="_blank"></a>
                        <a href="https://youtube.com/@agilexcellence?si=mPufsjp2gUyFNwJR" className="fa fa-youtube" target="_blank"></a>
                    </div>
                </div>
            </div>

            <div className="grupo-2">
                <small>&copy; 2024 <b> AGILEXCELLENCE</b> - All Rights Reserved.</small>
            </div>
        </footer>
    );

}

export {Footer};