import React from "react";
import "../styles/sectionTitle.css"

function SectionTitle ({BoldText, ligthText}){
    return (
        <div className="section-title-container">
            <h2 className="section-title-text"> <em>{BoldText}</em>{ligthText}</h2> 
        </div>
    );
}

export {SectionTitle};