import React from "react";

import WappIcon from "./../../../imgs/whatsapp.svg";

const WhatsappContactSpanish = () => {
  return (
    <a href="https://wa.me/+573245006792?text=Hi, I've been redirected from the Cidelac website and I want to chat with an Agent" target={"_blank"} className="tel-link">
      <img className="url-icon" alt="CallIcon" src={WappIcon} />
      <h1 className="tel-header"> Whatsapp Now! </h1>
    </a>
  );
};

export default WhatsappContactSpanish;