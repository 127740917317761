import React,{useEffect} from "react";

import { getGraphQl } from "./../hooks/ApiCalls";
import "./../styles/courseForm.css"

import { Spinner } from "./Spinner";

function CourseForm ({learningCourseId}) {

  const [values, setValues] = React.useState({
    name: "",
    lastname:"",
    email: "",
    dni: "",
    phone: "",
    learningCourseId: learningCourseId.learningCourseId,
  });

  const [status, setstatus] = React.useState(false)

  let graphQuery= `
      mutation{
          createStudentInscription(StudentInscription:{name:"${values.name}",lastname:"${values.lastname}",email:"${values.email}",dni:"${values.dni}",phone:"${values.phone}",learningCourseId:"${values.learningCourseId}"}) {
            InscriptionEnabled
            message
          }
        }
      `;

  function handleChange(evt) {
    const { target } = evt;
    const { name, value } = target;

    const newValues = {
      ...values,
      [name]: value,
    };
    setValues(newValues);
  }
  
  const handleSubmit = async (evt) => {
      setstatus(true);
      evt.preventDefault();

      try {
        await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
        setValues({
          name: "",
          lastname:"",
          email: "",
          dni: "",
          phone: "",
        })
      } catch (error) {
        alert("Inscripción Fallida");
      } finally {
        setstatus(false);
        alert("Inscripción satisfactoria");
        window.location.reload();
      }

  }

  const [dates, setdates] = React.useState("");

    let graphQueryDates= `
        query{
            GetLearningCoursesbylearningCourseId(LearningCourses:{learningCourseId:"${learningCourseId.learningCourseId}"}) {
            startDate
            endDate
            }
        }
    `;
    
    useEffect(() => {

        (async () => {
            const {data:{data:{GetLearningCoursesbylearningCourseId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQueryDates});
            setdates(GetLearningCoursesbylearningCourseId);
        })();

      }, []);

    return (
        <form className="course-form-container" onSubmit={handleSubmit}>
            <h1 className="course-form-title">¡Inscríbete HOY y prepárate para dar un paso en tu carrera de la mano de <label className="company-highlights">agilexcellence</label></h1>
            <h1 className="course-form-duration">Duración: <label className="course-form-dates">{dates.startDate} - {dates.endDate}</label> </h1>
            <div className="courseform-items-container">
                <input className="course-form-item" type="text" name = "name" placeholder="John" value={values.name} onChange={handleChange} required/>
                <input className="course-form-item" type="text" name = "lastname" placeholder="Doe" value={values.lastname} onChange={handleChange} required/>
                <input className="course-form-item" type="email" name = "email" placeholder="johndoe@email.com" value={values.email} onChange={handleChange} required/>
                <input className="course-form-item" type="number" name = "dni" placeholder="DNI Number" value={values.dni} onChange={handleChange} required/>
                <input className="course-form-item" type="text" name = "phone" placeholder="+57 305XXXXX" value={values.phone} onChange={handleChange} required/>
            </div>
            <p className="course-form-terms-conditions">* Al inscribirte aceptas nuestra política de tratamiento de datos personales</p>
            <button className="course-form-btn" type="submit">¡Inscribirme ahora! <div className="course-form-load-spinner">{status?<Spinner/>:""}</div></button>
        </form>
    );
}

export {CourseForm};