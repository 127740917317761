import React from "react";
import "./../../styles/bannerRoutes.css"

import routeIcon from "./../../imgs/CareerRoutes/Buterfly.png";

import { useNavigate } from "react-router-dom";

function BannerRoutes () {

    const navigate = useNavigate();
    const handleStartLearningPath = ()=> {
        window.open('https://wa.me/+573241854519?text=Hola, he sido redirigido desde agilexcellence.io, estoy interesado en iniciar mi camino como Technical Project Manager (TPM).', '_blank');
    };

    return(
        <div className="banner-routes-main-container">
            <div className="banner-routes-secundary-container">
                <div className="banner-routes-content-container">
                    <img src={routeIcon} alt="course route icon" className="banner-routes-icon"/>
                    <div className="banner-routes-content-text-container">
                        <h1 className="banner-routes-title">Ruta TPM</h1>
                        <h2 className="banner-routes-route-name">Technical Project Manager</h2>
                        <h1 className="banner-routes-text-description">¡Descubre el poder de la gestión de proyectos técnicos! Exploramos el rol fundamental del Technical Project Manager (TPM) en la ejecución exitosa de proyectos tecnológicos, desde la planificación estratégica hasta la entrega final. Con recursos valiosos y consejos prácticos, ayudamos a entender la importancia de un TPM competente para garantizar la eficiencia, la calidad y el cumplimiento de los objetivos del proyecto.</h1>
                        <div className="banner-routes-btn">
                            <a className="banner-routes-btn1" onClick={handleStartLearningPath}>COMIENZA AHORA</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export {BannerRoutes}