import React from "react";
import "./../styles/testimonial.css";

import LilianLopez from "./../imgs/Testimonials/LilianLopez.webp";
import NataliaAnchique from "../imgs/Testimonials/NataliaAnchique.webp";
import AngelicaAnchique from "../imgs/Testimonials/AngelicaAnchique.webp";
import DanielaMontt from "../imgs/Testimonials/DanielaMontt.webp";
import DanielaMontt2 from "../imgs/Testimonials/DanielaMontt2.webp";
import RobinsonGalvis from "../imgs/Testimonials/RobinsonGalvis.webp";

import {SectionTitle} from "./../components/SectionTitle";

function Testimonials () {
    return(
        <React.Fragment>
            
        <SectionTitle
        BoldText="Testimonios y dónde trabajan "
        ligthText=" nuestros estudiantes"
        />
        <section className="testimonials-main-container">
            <article className="testimonials-carousel-container">

                <div className="testimonials-carousel-container-div">
                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">El curso fue excepcional, el entrenador tiene un excelente dominio del tema y un don de enseñanza con el que muestra todo en un lenguaje muy sencillo y práctico.
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={DanielaMontt} alt="Daniela Montt Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Daniela Montt<span className="testimonials-author-company">- Universidad del Sinú</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Excelente curso, detalle y explicación. Super recomendada la academia. Recomiendo realizar mas actividades dinámicas para  poner en practica lo aprendido. 
                        <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={AngelicaAnchique} alt="Angelica Anchique Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Angelica Anchique<span className="testimonials-author-company">- Afinia</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">El contenido del curso, así como las herramientas aplicadas por los facilitadores han establecido en mi caso la puerta a un nuevo mundo. Definitivamente me enseñó que para ser un buen Scrum Master hay mucho por aprender.
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={RobinsonGalvis} alt="Robinson Gelvis Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Robinson Gelvis<span className="testimonials-author-company">- U. del Sinú</span></h5>
                        </div>
                    </figure>

                     <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">La academia está realmente interesada en que seas un Scrum Master profesional. Después de tanto buscar una buena academia, estoy segurísima de que tomé la mejor decisión.
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={DanielaMontt2} alt="Daniela Montt Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Daniela Montt<span className="testimonials-author-company">- Universidad del Sinú</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Fue espectacular, aprendí mucho del marco de trabajo Scrum, además nos preparan para presentar y ganar el examen para obtener la certificación. 
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={NataliaAnchique} alt="Natalia Anchique Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Natalia Anchique<span className="testimonials-author-company">- Flux Terpel</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Me encantó! Aprendí muchísimo de los conocimientos y experiencias compartidas, además de recibir excelentes consejos para aplicar como scrum master.  
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={LilianLopez} alt="Lilian Lopez Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Lilian López <span className="testimonials-author-company">- AB-Inbev</span></h5>
                        </div>
                    </figure>
                </div>

                <div className="testimonials-carousel-container-div">
                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">El curso fue excepcional, el entrenador tiene un excelente dominio del tema y un don de enseñanza con el que muestra todo en un lenguaje muy sencillo y práctico. 
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={DanielaMontt} alt="Daniela Montt Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Daniela Montt<span className="testimonials-author-company">- Universidad del Sinú</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Excelente curso, detalle y explicación. Super recomendada la academia. Recomiendo realizar mas actividades dinámicas para  poner en practica lo aprendido. 
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={AngelicaAnchique} alt="Angelica Anchique Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Angelica Anchique<span className="testimonials-author-company">- Afinia</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">El contenido del curso, así como las herramientas aplicadas por los facilitadores han establecido en mi caso la puerta a un nuevo mundo. Definitivamente me enseñó que para ser un buen Scrum Master hay mucho por aprender.
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={RobinsonGalvis} alt="Robinson Gelvis Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Robinson Gelvis<span className="testimonials-author-company">- U. del Sinú</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">La academia está realmente interesada en que seas un Scrum Master profesional. Después de tanto buscar una buena academia, estoy segurísima de que tomé la mejor decisión.
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={DanielaMontt2} alt="Daniela Montt Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Daniela Montt<span className="testimonials-author-company">- Universidad del Sinú</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Fue espectacular, aprendí mucho del marco de trabajo Scrum, además nos preparan para presentar y ganar el examen para obtener la certificación. 
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={NataliaAnchique} alt="Natalia Anchique Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Natalia Anchique <span className="testimonials-author-company">- Flux Terpel</span></h5>
                        </div>
                    </figure>

                    <figure className="testimonials-card">
                        <blockquote className="testimonial-card-quote">Me encantó! Aprendí muchísimo de los conocimientos y experiencias compartidas, además de recibir excelentes consejos para aplicar como scrum master.  
                            <div class="arrow"></div>
                        </blockquote>
                        <img className="testimonial-card-img" src={LilianLopez} alt="Lilian Lopez Picture"/>
                        <div class="testimonials-author-info">
                            <h5 className="testimonials-author-name">Lilian López <span className="testimonials-author-company">- AB-Inbev</span></h5>
                        </div>
                    </figure> 
                </div>
            
            </article>
        </section>
        </React.Fragment>
    )
}

export {Testimonials}