import React from "react";
import "../styles/difficulty.css";

function Difficulty ({level}) {

    if (level === "Intermedio") {
        return (
            <div className="diff¡culty-main-container">
                <div class="signal-icon medium">
                    <div class="signal-bar"></div>
                    <div class="signal-bar"></div>
                    <div class="signal-bar"></div>
                </div>
                <h3 className="difficulty-level-text">{level}</h3>
            </div>
            
        )
    }

    if (level === "Avanzado") {
        return (
            <div className="diff¡culty-main-container">
                <div class="signal-icon strong">
                    <div class="signal-bar"></div>
                    <div class="signal-bar"></div>
                    <div class="signal-bar"></div>
                </div>
                <h3 className="difficulty-level-text">{level}</h3>
            </div>
            
        )
    }

    return(
        <div className="diff¡culty-main-container">
            <div class="signal-icon weak">
                <div class="signal-bar"></div>
                <div class="signal-bar"></div>
                <div class="signal-bar"></div>
            </div>
            <h3 className="difficulty-level-text">{level}</h3>
        </div>
    );
}

export {Difficulty}