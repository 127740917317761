import React, {useCallback,useEffect} from "react";
import "../styles/navbar.css";

import logo from "../imgs/Brand/logo-main.webp";
import hambMenu from "./../imgs/icon_menu.svg";

import { useNavigate } from "react-router-dom";
import { ToogleContext } from "../hooks/ToogleContext";

import { faHamburger } from "@fortawesome/free-solid-svg-icons";
import { LanguageToogle } from "./LanguageToogle";

function Navbar (){

    const {openHamburguer, setopenHamburguer} = React.useContext(ToogleContext);
    const [shouldOpenCoursesMenu,setshouldOpenCoursesMenu] = React.useState(false);
    const [isAlredyinSection,setisAlredyinSection] =  React.useState(false) ;

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
          setshouldOpenCoursesMenu(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
    
        // return () => {
        //   document.removeEventListener("keydown", escFunction, false);
        // };
      }, [escFunction]);

    const openHamburger = () => {
        setopenHamburguer(!openHamburguer);
    };

    const handleMouseOverCourses = () => {
        // if(shouldOpenCoursesMenu) return;
        setshouldOpenCoursesMenu(!shouldOpenCoursesMenu);
    };

    const handleMouseOverSection = () => {
        console.log("in section")
        if(isAlredyinSection) return;
        setisAlredyinSection(true);
    };

    const handleMouseOutCourses = () => {
        console.log("out ")
        if(!shouldOpenCoursesMenu && !isAlredyinSection)  return;
        setshouldOpenCoursesMenu(false);
    };

    const navigate = useNavigate();

    return (
        <header className="navbar-web">
            <div className={`sidenav ${openHamburguer ? "sidenavOpen" : "otherclass"}`}>
                <div className="sidenavOpen-inner-container">
                    <div className="sidenav-mobile-main-info-block">
                        <a className="item" onClick ={() => navigate("/")} >Home</a>
                        <a className="item" onClick ={() => navigate("/")}>Próximos Cursos</a>
                        <a className="item" onClick ={() => navigate("/aboutUs")}>Sobre Nosotros</a>
                        <a className="item" onClick ={() => navigate("/academy")}>Academy de Scrum Master</a>
                    </div>
                    
                    <div className="sidenav-mobile-learning-paths-block">
                        <h2 className="sidenav-mobile-block-title">Rutas de Aprendizaje</h2>
                        <a className="item" onClick ={() => navigate("/learning-path/TPM/34b6613b-2aeb-49d1-8fc6-8bc43b99edfa")}>Technical Project Manager</a>
                    </div>
                    
                    <div className="sidenav-mobile-resources-block">
                        <h2 className="sidenav-mobile-block-title">Recursos en Linea</h2>
                        <a className="item" href="https://blog.agilexcellence.io/" target="__blank">Blogs</a>
                        <a className="item" href="https://portal.agilexcellence.io" target="_blank">Portal Estudiantes</a>
                        <a className="item" href="https://quizapp.agilexcellence.io" target="_blank">Simuladores</a>
                        <a className="item" onClick ={() => navigate("/certificates")}>Validador de Certificados Emitidos</a>
                    </div>

                    <div className="language-toogle-btn">
                        {/* <LanguageToogle/> */}
                    </div>
                </div>
                
            </div>
            <button className={`hamb-menu ${openHamburguer && "opened"} `} onClick={openHamburger}>  
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                    <path class="line line2" d="M 20,50 H 80" />
                    <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                </svg> 
            </button>
            <div className="logo">
                <img className="navbar-logo" onClick={() => navigate("/")} src= {logo}/>
            </div>
            <nav>
                <ul className="navbar-web-items">
                    <li className="navbar-web-item" onClick={() => navigate("/")}>HOME</li>
                    <li className={`navbar-web-item ${shouldOpenCoursesMenu && "navbar-web-item-hover"}`} onClick={handleMouseOverCourses}>PORTFOLIO</li>
                        <div className= {`navbar-web-courses-list ${shouldOpenCoursesMenu && "navbar-web-courses-list-hover" } `} >
                            <div className="navbar-courses-list-container">
                                <div className="navbar-courses-list-block">
                                    <h2 className="navbar-courses-list-block-title">Certificaciones Scrum</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Professional Scrum Master - PSMI</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Professional Scrum Master - PSM2</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Professional Scrum Product Owner - PSPO</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Professional Scrum Developer - PSDI</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Professional Scrum Facilitation Skills - PSFS</li>
                                    <h2 className="navbar-courses-list-block-title">Certificaciones Kanban</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Kanban System Design - KSD</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Kanban System Improvement - KSI</li>
                                    <h2 className="navbar-courses-list-block-title">Certificaciones Management 3.0</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Management 3.0 Foundation Workshop</li>
                                </div>

                                <div className="navbar-courses-list-block">
                                    <h2 className="navbar-courses-list-block-title">Cursos para Scrum Masters</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Fundamentos de Gestión de Proyectos PMI</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Fundamentos de documentación gráfica</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Facilitación de equipos ágiles</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Product Discovery</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Flow Management & Mapping</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Curso de Fundamentos de Jira </li>
                                    <h2 className="navbar-courses-list-block-title">e-learning</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Suscríbete al e-learning</li>
                                    <h2 className="navbar-courses-list-block-title">Academy</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/academy")}>Academy de Scrum Masters</li>
                                </div>

                                <div className="navbar-courses-list-block">
                                    <h2 className="navbar-courses-list-block-title">Servicios Empresariales</h2>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Consultoria Empresarial</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Cursos in Company</li>
                                    <li className="navbar-web-sub-item" onClick ={() => navigate("/")}>Contrata tu Scrum Master</li>
                                    <h2 className="navbar-courses-list-block-title">Validador de Certificaciones</h2>
                                    <li className="navbar-web-sub-item"> Certificados emitidos</li>
                                </div>
                                
                            </div>
                            
                        </div>
                    <li className="navbar-web-item" onClick ={() => navigate("/aboutUs")}>ABOUT US</li>
                    <li className="navbar-web-item"> <a href="https://blog.agilexcellence.io/" target="__blank">BLOGS</a></li>
                </ul>
            </nav>

            
            <a className="navbar-join-btn" href="https://portal.agilexcellence.io" target="_blank"><button className="navbar-regular-button" type="button" >Join</button></a>
        </header>
    );
}

export {Navbar};