import React from "react";
import "../styles/whatsAppButton.css";

import whatsappIcon from "./../imgs/whatsapp.svg";

const btn_whatsapp = ()=> {
    window.open('https://wa.me/+573241854519?text=Hola, he sido redirigido desde agilexcellence.io, y tengo algunas dudas por resolver.', '_blank');
};

function WhatsAppButton (){

    return (
        <img src={whatsappIcon} onClick={btn_whatsapp} className= "WhatsAppButton" id="WhatsAppButton"/>
    );
}

export {WhatsAppButton};