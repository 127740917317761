import React from "react";
import "./../../styles/businessMetrics.css";

import {SectionTitle} from "./../SectionTitle";
import planeIcon from "./../../imgs/BusinessMetricsIcons/flight-plane.svg";
import starIcon from "./../../imgs/BusinessMetricsIcons/star.svg";
import studentIcon from "./../../imgs/BusinessMetricsIcons/student.svg";
import courseIcon from "./../../imgs/BusinessMetricsIcons/video-course.svg";

function BusinessMetrics () {
    return(
        
        <section className="business-metrics-main-container">
        
            <div className="business-metrics-container">

                <div className="business-metrics-card">
                    <img className="business-metrics-img" src={studentIcon}/>
                    <h2 className="business-metric-number">+800</h2>
                    <h3 className="business-metric-text">Estudiantes entrenados</h3>
                </div>

                <div className="business-metrics-card">
                    <img className="business-metrics-img" src={courseIcon}/>
                    <h2 className="business-metric-number">58</h2>
                    <h3 className="business-metric-text">Cursos impartidos</h3>
                </div>

                <div className="business-metrics-card">
                    <img className="business-metrics-img" src={planeIcon}/>
                    <h2 className="business-metric-number">18.400</h2>
                    <h3 className="business-metric-text">Horas de entrenamiento</h3>
                </div>

                <div className="business-metrics-card">
                    <img className="business-metrics-img" src={starIcon}/>
                    <h2 className="business-metric-number">5/5</h2>
                    <h3 className="business-metric-text">+200 calificaciones</h3>
                </div>

            </div>

        </section>
    );
}

export { BusinessMetrics};