import React from 'react';
import { Navbar } from "./../components/Navbar";
import { BannerPages } from "./../components/BannerPages";
import { Footer } from "./../components/Footer";
import { ScrollTop } from "./../components/ScrollTop";
import { ToogleMenu } from "./../components/ToogleMenu";
import { ChatBot } from "./../components/ChatBot";

function OurStrategy() {

  return (
    <React.Fragment>
      <Navbar/>
      <BannerPages/>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
      <Footer/>
      <ScrollTop/>
      <ToogleMenu/>
      <ChatBot/>
    </React.Fragment>
  )
}

export {OurStrategy};
