import React from "react";
import "./../styles/trainerProfile.css";

import awardIcon from "./../imgs/Trainers/trainerAwardsIcon.svg";
import experienceIcon from "./../imgs/Trainers/trainerExperience.svg";
import techingExperienceIcon from "./../imgs/Trainers/trainerTeachingIcon.svg";
import hobbieIcon from "./../imgs/Trainers/trainerHobbie.svg";
import linkedinIcon from "./../imgs/Trainers/linkedinIcon.svg";

import JesusMartheyn from "./../imgs/Trainers/JesusMartheyn.jpg";

function TrainerProfile () {
    return(
        <div className="trainer-profile-main-container">
            <div className="trainer-profile-inner-container">
                <img src= {JesusMartheyn} className="trainer-profile-img" alt="trainer of the course image"/>
                <div className="trainer-profile-info-block">
                    <div className="trainer-profile-info-block-header">
                        <h2 className="trainer-profile-info-name">Jesus Martheyn</h2> 
                        <a href="https://www.linkedin.com/in/jesus-martheyn/" target="__blank"><img src={linkedinIcon} className="trainer-profile-info-linkedin-icon" alt="trainer linkeding address icon"/></a>
                    </div>

                    <h4 className="trainer-profile-info-role">Trainer & co-founder Agilexcellence</h4>
                    <p className="trainer-profile-info-description">Ingeniero Mecatrónico, Magister en Gerencia de Proyectos, Agile Coach, Scrum Master, Project Manager, Full Stack Developer, Piloto de Aviones.</p>
                    
                    <div className="trainer-profile-items-container">
                        <img src={techingExperienceIcon} className="trainer-profile-item-icon" alt="trainer teaching experience icon"/>
                        <p className="trainer-profile-item-description">13 años como docente Universitario y Posgrados</p>
                    </div>
                    <div className="trainer-profile-items-container">
                        <img src={experienceIcon} className="trainer-profile-item-icon" alt="trainer global experience icon"/>
                        <p className="trainer-profile-item-description">10 años liderando equipos de proyectos internacionales</p>
                    </div>
                    <div className="trainer-profile-items-container">
                        <img src={awardIcon} className="trainer-profile-item-icon" alt="trainer awards icon"/>
                        <p className="trainer-profile-item-description">Publicaciones en revistas y Patente de invención</p>
                    </div>
                    <div className="trainer-profile-items-container">
                        <img src={hobbieIcon} className="trainer-profile-item-icon" alt="trainer hobbies icon"/>
                        <p className="trainer-profile-item-description">Amante de la aviación, videojuegos y de aprender cada día algo nuevo</p>
                    </div>

                    <div className="trainer-profile-badges">

                    </div>
                </div>
                
            </div>
        </div>
    )
}

export {TrainerProfile};