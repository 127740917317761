import React  from "react";
import "../../styles/certificatesContent.css";

import { Loading } from "../Loading";

function CertificatesContent () {

    const [searchStatus, setsearchStatus] = React.useState("initial");

    const handleSearch = () => {
        
        setTimeout(()=> {setsearchStatus ("loading")},1000);

        setTimeout(()=> {setsearchStatus ("not-results-found")},3000);

        // setTimeout(()=> {setsearchStatus ("results-found")},5000);

    };

    return(
        <div className="certificate-validator-main-container">
            <div className="certificate-validator-welcome-box">
                <h1 className="certificate-validator-welcome-box-title"> Validador de Certificados</h1>
                <p className="certificate-validator-welcome-box-description">Bienvenido al validador de certificados de aprobación de cursos, workshops y entrenamientos de certifación. Acá podrás obtener la información relacionada a nuestros certificados emitidos a partir del número único asignado a cada certificado.
                Los certificados emitidos son a partir del cumplimiento de los requisitos de cada curso, workshop o entrenamiento de certifación de parte de Pisoco Academy, para validar u obtener información de los certificados internacionales, es necesario remitirse al portar indicado del ente certificador.</p>
            </div>

            <div className="certificate-validator-search-box">
                <h2 className="certificate-validator-search-box-title">Búsqueda de Certificado por ID</h2>
                <form className="certificate-validator-search-box-form">
                    <input className="certificate-validator-search-box-form-input" type="text" placeholder="xxxx-xxxx-xxxx-xxx"/>
                    <button className="certificate-validator-search-box-form-button" onClick={handleSearch}>Search</button>
                </form>

            </div>

            {searchStatus ==="loading" && <Loading />}

            {searchStatus ==="not-results-found" && 
                <div className="certificate-validator-not-found">
                    <p> No results found </p>
                </div>
            }

            {searchStatus ==="results-found" && 
                <div className="certificate-validator-table-box">
                    <table className="certificate-validador-results-table">
                        <tr className="certificate-validator-table-titles">
                            <td className="certificate-validator-title">Name</td>
                            <td className="certificate-validator-title">Last Name</td>
                            <td className="certificate-validator-title">Course Name</td>
                            <td className="certificate-validator-title">Issue Date</td>
                            <td className="certificate-validator-title">View</td>
                        </tr>
    
                        <tr className="certificate-validator-table-entries">
                            <td className="certificate-validator-table-item">Jesus Orlando</td>
                            <td className="certificate-validator-table-item">Martheyn Berbesi</td>
                            <td className="certificate-validator-table-item">Professional Scrum Master Course - PSM1</td>
                            <td className="certificate-validator-table-item">25/01/2023</td>
                            <td className="certificate-validator-table-item-view">View</td>
                        </tr>
    
                        <tr className="certificate-validator-table-entries">
                            <td className="certificate-validator-table-item">Jesus Orlando</td>
                            <td className="certificate-validator-table-item">Martheyn Berbesi</td>
                            <td className="certificate-validator-table-item">Professional Scrum Master Course - PSM1</td>
                            <td className="certificate-validator-table-item">25/01/2023</td>
                            <td className="certificate-validator-table-item-view">View</td>
                        </tr>
    
                        <tr className="certificate-validator-table-entries">
                            <td className="certificate-validator-table-item">Jesus Orlando</td>
                            <td className="certificate-validator-table-item">Martheyn Berbesi</td>
                            <td className="certificate-validator-table-item">Professional Scrum Master Course - PSM1</td>
                            <td className="certificate-validator-table-item">25/01/2023</td>
                            <td className="certificate-validator-table-item-view">View</td>
                        </tr>
                        
                    </table>
                </div>
            }

        </div>
    );

    
}

export { CertificatesContent };