import React from "react";
import "./styles/App.css";
import "./styles/chatbot.css";
import "./styles/root.css";

import Home from './pages/Home';
import { OurStrategy} from "./pages/OurStrategy";
import { EmbracingSustainability } from "./pages/EmbracingSustainability";
import { PrivacyStatement } from "./pages/PrivacyStatement";
import { SocialResponsability } from "./pages/SocialResponsability";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { ToogleProvider } from "./hooks/ToogleContext";
import { CertificatesValidator } from "./pages/CertificatesValidator";
import { AboutUs } from "./pages/AboutUs";
import { ScrumMaster } from "./pages/Cursos/ScrumMaster";
import { ScrumMasterPSM2 } from "./pages/Cursos/ScrumMasterPSM2";
import { Academy } from "./pages/Academy/Academy";
import { Blog1 } from "./pages/Blogs/Blog1";
import { KanbanSystemDesing } from "./pages/Cursos/KanbanSystemDesing";
import { KanbanSystemImprovement } from "./pages/Cursos/KanbanSystemImprovement";
import { JiraFundamentals } from "./pages/Cursos/JiraFundamentals";
import { IntroduccionProyectos } from "./pages/Cursos/IntroduccionProyectos";
import { DocumentacionGrafica } from "./pages/Cursos/DocumentacionGrafica";

import { TPM } from "./pages/CareerRoutes/TPM";

const router = createHashRouter ([
  {
      path: "/",
      element: <Home/>,
  },
  {
    path: "/certificates",
    element: <CertificatesValidator/>,
  },
  {
    path: "/aboutUs",
    element: <AboutUs/>,
  },
  {
    path: "/strategy",
    element: <OurStrategy/>,
  },
  {
    path: "/sustainability",
    element: <EmbracingSustainability/>,
  },
  {
    path: "/social-responsability",
    element: <SocialResponsability/>,
  },
  {
    path: "/privacy",
    element: <PrivacyStatement/>,
  },
  {
    path: "/learning-path/TPM/:learningRouteId",
    element: <TPM/>,
  },
  {
    path: "/courses/scrum-master/:learningCourseId",
    element: <ScrumMaster/>,
  },
  {
    path: "/courses/scrum-master-psm2/:learningCourseId",
    element: <ScrumMasterPSM2/>,
  },
  {
    path: "/courses/kanban-system-design/:learningCourseId",
    element: <KanbanSystemDesing/>,
  },
  {
    path: "/courses/kanban-system-improvement/:learningCourseId",
    element: <KanbanSystemImprovement/>
  },
  {
    path: "/courses/jira-fundamentals/:learningCourseId",
    element: <JiraFundamentals/>
  },
  {
    path:"/courses/introduccion-proyectos/:learningCourseId",
    element: <IntroduccionProyectos/>
  },
  {
    path:"/courses/documentacion-grafica/:learningCourseId",
    element: <DocumentacionGrafica/>
  },
  {
    path: "/academy",
    element: <Academy/>,
  },
  {
    path:"/how-use-effectively-moving-motivators-in-your-team",
    element: <Blog1/>
  },

]);

function App() {

  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
   
  );
}

export default App;
