import React from "react";
import "./../styles/coursePresentation.css";

import { CourseForm } from "./CourseForm";
import { Difficulty } from "./../components/Difficulty";
import { StarRating } from "./StarRating";

function CoursePresentation ({learningCourseId, courseName, courseDescription, videoUrl, level}){
    return(
        <div className="course-presentation-container">
                <div className="course-presentation-left">
                    <h1 className="course-presentation-title"> {courseName} </h1>  
                    <div className="course-presentation-quick-info-block">
                        <label className="course-presentation-label">Online</label>
                        <Difficulty
                            level={level}
                        />
                        <StarRating/>
                    </div>
                    
                    <p className="course-presentation-text"> {courseDescription} </p>
                    <iframe className="video-youtube" width="560" height="315" src= {`https://www.youtube.com/embed/${videoUrl}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
                   
                </div>
                <div className="course-presentation-right">
                    <div className="course-registration-form">
                        <CourseForm
                        learningCourseId = {learningCourseId}
                        />
                    </div>
                </div>
            </div>
    );

}

export { CoursePresentation}