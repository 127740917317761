import React, {useEffect, useState} from "react";
import "./../../styles/careerRouteCarousel.css";

import { useParams } from "react-router-dom";
import { getGraphQl } from "../../hooks/ApiCalls";

import { CareerRouteCard } from "./../../components/CareerRoutes/CareerRouteCard";

function CareerRouteCarousel ({name, description, competency}) {

    let {learningRouteId} = useParams();
    const [RouteItemsList, setRouteItemsList] = React.useState([]);
    
    let graphQuery = `
        query {
            GetRouteItemsPerCompetency(RouteItem:{learningRouteId:"${learningRouteId}",competency:"${competency}"}) {
            id
            routeItemId
            productId
            name
            description
            difficulty
            courseIdentifier
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetRouteItemsPerCompetency}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setRouteItemsList(GetRouteItemsPerCompetency);
        })();

      }, []);
    
    return(
        <div className="career-route-carousel-main-container">
            <div className="career-route-carousel-inner-container">
                <div className="career-route-carousel-left-container">
                    <h1 className="career-route-carousel-name">{name}</h1>
                    <h2 className="career-route-carousel-description">{description}</h2>
                </div>
                    
                <div className="career-route-carousel-cards-container">
                    {   
                        RouteItemsList.map((RouteItem) => ( 
                            <CareerRouteCard
                                key={RouteItem.routeItemId} 
                                name={RouteItem.name}
                                courseIdentifier={RouteItem.courseIdentifier}
                                difficulty={RouteItem.difficulty}
                            />
                        ))
                    }
                </div>
                
            </div>
        </div>
    );
}

export {CareerRouteCarousel}